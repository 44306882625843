<template>
<nav class="navbar navbar-expand-sm navbar-dark bg-dark sticky" role="navigation" style="height: 70px; z-index: 1001;">
    <div class="container" style="max-width: 100%;">
        <!-- Brand and toggle get grouped for better mobile display -->
        <router-link to="/" class="navbar-brand mr-auto">
            <img src="@/assets/bakerboislogo2.png" alt="logo" style="float: left; width: 100px; height: 70px; background: transparent;" />
        </router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTop" aria-controls="navbarTop" aria-expanded="false" aria-label="Toggle navigation" @click="toggleNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" style="padding: 2px; z-index: 1000; position: relative; text-align: center;" id="navbarTop" :class="{show: isNavOpen}">
            <ul class="navbar-nav mr-auto"></ul>
            <ul class="nav navbar-nav">
                <router-link to="/admin" tag="li" v-if="isAdminUser" class="nav-item" style="background-color: #e8e8e8;" active-class="active">
                    <a class="nav-link">ADMIN</a>
                </router-link>
                <li v-if="isLoggedIn && currentUser" class="li-pointer nav-item nav-link points title" style="height: 55px; border: unset;">
                    <button @click="fetchData(); showUserInfoOverlay()" class="user-info-button">
                        <i class="fas fa-gift"></i>
                    </button>
                </li>
                <router-link @click="toggleNavbar();" to="/login" tag="li" v-if="!isLoggedIn" class="nav-item" active-class="active">
                    <a class="nav-link"><i class="fas fa-sign-in-alt"></i> Login</a>
                </router-link>
                <li v-if="isLoggedIn" class="li-pointer nav-item">
                    <a @click="clearCartValue(); logout(); toggleNavbar()" class="nav-link"><i class="fas fa-sign-out-alt" style="margin-right: 5px;"></i>Logout</a>
                </li>
                <router-link to="/register" tag="li" v-if="!isLoggedIn" class="nav-item" active-class="active">
                    <a class="nav-link"><i class="fas fa-user-plus"></i> Register</a>
                </router-link>
                <li @click="toggleNavbar()">
                    <router-link v-if="isLoggedIn" to="/cart" class="btn btn-success navbar-btn" tag="button" style="font-weight: 500;" @click="toggleNavbar()">
                        <i v-if="cartItemList.length === 0" class="fas fa-shopping-cart"></i><span v-if="cartItemList.length === 0" class="badge badge-light" style="margin-left: 15px;">{{ numItems }} ($0)</span>
                        <i v-if="cartItemList.length > 0" class="fas fa-shopping-cart"></i><span v-if="cartItemList.length > 0" class="badge badge-light" style="margin-left: 15px;">{{ numItems }} ($ {{ cartValue }})</span>
                    </router-link>
                    <router-link v-if="!isLoggedIn" to="/cart" class="btn btn-success navbar-btn" tag="button" style="font-weight: 500;" @click="toggleNavbar()">
                        <i class="fas fa-shopping-cart"></i><span class="badge badge-light" style="margin-left: 15px;"></span>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>

    <!-- Overlay for user information -->
    <div v-if="isUserOverlayVisible" class="user-info-overlay">
        <div class="user-info-content">
            <div class="header">
                <h2>Welcome, {{ userName }}!</h2>
                <p>Your Reward Points: <strong class="highlight">{{ formattedUserPoints }}</strong></p>
            </div>
            <div class="body">
                <p v-if="!formattedUserPoints || formattedUserPoints <= 0" class="excitement">🎉 Order now to start earning rewards! 🎉</p>
                <p v-else class="excitement">🎉 Congratulations on earning reward points! 🎉</p>
                <div style="border: 2px groove white; border-radius: 15px; padding: 15px;">
                    <p class="instructions">Redeem points for exciting discounts:</p>
                    <ul class="benefits">
                        <li style="font-weight: bold;" v-if="!filteredTransactions || filteredTransactions.length <= 0"><span class="bullet"><i class="fas fa-star"></i></span> Enjoy a free roll after your first order</li>
                        <li style="font-weight: bold;" v-if="filteredTransactions && filteredTransactions.length === 1"><span class="bullet"><i class="fas fa-star"></i></span> Enjoy a free roll with your next order</li>
                        <li><span class="bullet">•</span> Unlock BakerBois merchandise</li>
                        <li><span class="bullet">•</span> Redeem 300 points for $10 off</li>
                        <li><span class="bullet">•</span> Redeem 500 points for $30 off</li>
                        <!-- Add more items as needed -->
                    </ul>
                </div>
            </div>
            <div class="footer">
                <button @click.prevent="hideUserInfoOverlay">Got it!</button>
            </div>
        </div>
    </div>

</nav>
</template>

<script>
import {
    database
} from '../config/firebaseConfig'; // Ensure you're importing the database instance
import {
    ref,
    onValue
} from 'firebase/database';
import {
    mapActions,
    mapGetters,
    mapState
} from 'vuex';

export default {
    data() {
        return {
            isNavOpen: false,
            isUserOverlayVisible: false,
            groupedTransactions: {}, // Initialize as an empty object to group transactions
            users: [],
            authorizedEmails: ['angelrosario624@gmail.com', 'mhaye20@gmail.com', 'aj@bakerbois.com']
        }
    },
    computed: {
        ...mapState(['isUserDataLoaded']),
        ...mapGetters(['isLoggedIn', 'cartValue', 'currentUser', 'cartItemList', 'userPhoneNumber', 'isUserDataLoaded']),
        numItems() {
            return this.cartItemList.reduce((total, item) => {
                total += item.quantity;
                return total
            }, 0);
        },
        isAdminUser() {
            return this.currentUser && this.authorizedEmails.includes(this.currentUser.email);
        },
        filteredTransactions() {
            // Get transactions of the current user only
            // const currentUserTransactions = this.groupedTransactions[this.currentUser.uid] || [];
            return this.groupedTransactions[this.currentUser.uid]

            // Filter out blank items for each transaction
            // return currentUserTransactions.map(transaction => {
            //     return {
            //         ...transaction,
            //         items: transaction.items.filter(item => item.title || item.price !== undefined)
            //     };
            // });
        },
        formattedUserPoints() {
            // Check if userPoints is null or undefined
            if (!this.currentUser.points) {
                return "0";
            } else {
                // Return userPoints as is
                return this.userPoints;
            }
        },
        userEmail() {
            return this.currentUser.email;
        },
        userName() {
            return this.currentUser.name;
        },
        userPhone() {
            return this.currentUser.phoneNumber;
        },
        userPoints() {
            return this.currentUser.points ? this.currentUser.points : '';
        },
        loggedInUserPhoneNumber() {
            return this.currentUser.phoneNumber
        }
    },
    methods: {
        ...mapActions(['logout', 'fetchUserData']), // Add fetchUserData to mapped actions
        toggleNavbar() {
            // Toggle the navbar state
            this.isNavOpen = !this.isNavOpen;
        },
        async checkAdminAccess() {
            // Refresh currentUser data
            await this.fetchUserData();

            // Check if the currentUser's email is authorized after refresh
            if (this.authorizedEmails.includes(this.currentUser.email)) {
                // Proceed to admin page if authorized
                this.$router.push('/admin');
            } else {
                // Optional: Show an error message or redirect if not authorized
                alert('You are not authorized to access the admin page.');
                this.$router.push('/'); // Redirect to home page or any other page
            }

            // Close the navbar after operation
            this.toggleNavbar();
        },
        clearCartValue() {
            this.cartValue = 0;
        },
        showUserInfoOverlay() {
            this.isUserOverlayVisible = true;
        },
        // Method to hide the user info overlay
        hideUserInfoOverlay() {
            this.isUserOverlayVisible = false;
        },
        closeNavbarOnOutsideClick(event) {
            const clickedElement = event.target;
            if (!this.$el.contains(clickedElement) || clickedElement.classList.contains('nav-link')) {
                this.isNavOpen = false;
            }
        },
        // Call fetchUserData when the component is mounted
        async fetchData() {
            // Call fetchUserData and wait for it to complete
            await this.fetchUserData();
        }
    },
    mounted() {
        document.addEventListener('click', this.closeNavbarOnOutsideClick);

        // this.fetchData();

        const usersRef = ref(database, "users");
        onValue(usersRef, (snapshot) => {
            this.users = snapshot.val();
        });

        const transactionsRef = ref(database, "transactions");
        onValue(transactionsRef, (snapshot) => {
            const groupedTransactions = {};
            snapshot.forEach((userSnapshot) => {
                const userId = userSnapshot.key;
                const userTransactions = userSnapshot.val();
                groupedTransactions[userId] = Object.values(userTransactions).map(transaction => ({
                    transactionId: transaction.id, // Assuming each transaction has an id
                    ...transaction,
                }));
            });
            this.groupedTransactions = groupedTransactions;
        });
    },
}
</script>

<style scoped>
.user-info-button {
    padding: 10px 20px;
    font-size: 16px;
    border: 2px solid #aeaeae;
    border-radius: 20px;
    background-color: #393939;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    align-items: center;
}

.user-info-button:hover {
    background-color: #ffffff61;
}

.apple-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.user-info-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-info-content {
    background-color: #f8f8f8f0;
    padding: 40px;
    border-radius: 20px;
    max-width: 600px;
    text-align: center;
    font-family: 'Comic Sans MS';
    /* Fun and playful font */
    border: 2px solid #3f1c3c;
    /* Pink border */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    /* Soft shadow effect */
}

.header h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #3c312a;
    /* Pink color for excitement */
}

.highlight {
    color: #5d506c;
    font-size: 30px;
}

.body {
    margin-bottom: 30px;
}

.excitement {
    font-size: 20px;
    margin-bottom: 20px;
}

.instructions {
    font-size: 18px;
    margin-bottom: 20px;
}

.benefits {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.benefits li {
    font-size: 16px;
    margin-bottom: 10px;
}

.bullet {
    color: #3f1c3c;
    /* Pink color for bullets */
    font-size: 20px;
    margin-right: 5px;
}

.footer {
    margin-top: 20px;
}

.footer button {
    padding: 15px 30px;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    background-color: #313131;
    /* Pink color for button */
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.footer button:hover {
    background-color: #4f4f4f;
    /* Darker pink color on hover */
}

.points {
    font-weight: 300;
    background-color: #343a40;
    line-height: 1.2em;
    text-align: center;
    display: flex;
    color: white !important;
    font-family: Verdana, sans-serif;
}

.points:hover {
    background-color: #ffffff00;
    border: unset;
}

.nav {
    align-items: center;
}

.navbar-btn a {
    color: white;
}

.btn {
    border: 2px outset #000000;
    background-color: #c58a59;
}

.btn:hover {
    border: 2px inset #7b4646;
    background-color: #9b6c46;
}

.li-pointer {
    cursor: pointer;
}

.li-pointer:hover {
    cursor: pointer;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.title-word {
    animation: color-animation 12s linear infinite;
}

.title-word-1 {
    --color-1: #ffffa0;
    /* Light salmon */
    --color-2: #FFD700;
    /* Gold */
    --color-3: #FFCC80;
    /* Pastel orange */
}

.title-word-3 {
    --color-1: #fff6b2;
    /* Dark orange */
    --color-2: #FFD700;
    /* Gold */
    --color-3: #FFCC80;
    /* Pastel orange */
}

.title-word-4 {
    --color-1: #FF7F50;
    /* Coral */
    --color-2: #FFD700;
    /* Gold */
    --color-3: #FFCC80;
    /* Pastel orange */
}

@keyframes color-animation {
    0% {
        color: var(--color-1)
    }

    32% {
        color: var(--color-1)
    }

    33% {
        color: var(--color-2)
    }

    65% {
        color: var(--color-2)
    }

    66% {
        color: var(--color-3)
    }

    99% {
        color: var(--color-3)
    }

    100% {
        color: var(--color-1)
    }
}

.container {
    place-items: center;
    text-align: center;
}

.title {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .points {
        background: rgba(255, 0, 0, 0);
    }

    .user-info-button {
        padding: 14px 11px;
        margin-left: 9px;
    }

    .nav {
        -webkit-box-align: center;
        align-items: center;
        z-index: 99999;
        position: relative;
        background: #1f1f1f9c;
        display: flex;
        border-radius: 5px;
        border: 2px groove #ffffff38;
        align-content: space-around;
        flex-direction: row;
        justify-content: center;
    }
}

@media (max-width: 575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-xl {
        padding-right: 0;
        padding-left: 0;
        margin-top: -24px;
    }
}
</style>
