import { ref } from '../../config/firebaseConfig';

const state = {
  isLoading: true,
  transactionList: [],
};

const mutations = {
  'UPDATE_TRANSACTION_LIST' (state, transactionList) {
    state.transactionList = transactionList;
    state.isLoading = false;
  },
};

const actions = {
  listenToTransactionList({ commit }) {
    // Adjust the Firebase query path as per your database structure
    return ref
      .child("1adTQ512s0HkBRmzumb5GKqfkXB0mClz1ekMkmTmQ0EY")
      .child("transactions")
      .on("value", (transactions) => {
        commit("UPDATE_TRANSACTION_LIST", transactions.val());
      });
  },
};

const getters = {
  transactions: (state) => {
    return state.transactionList;
  },
  isTransactionLoading: (state) => {
    return state.isLoading;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
