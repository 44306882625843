<template>
    <tr>
      <td data-th="Product">
        <div class="row">
          <div class="col-sm-2 d-none d-sm-block">
            <img :src="cartItem.thumbnail_url" alt="..." class="img-fluid" />
          </div>
          <div class="col-sm-10">
            <h4 class="nomargin" style="vertical-align: middle !important">{{ cartItem.title }}</h4>
            <!-- Hide description on mobile -->
            <p style="vertical-align: middle !important" class="description" v-if="!isMobile">{{ cartItem.description }}</p>
          </div>
        </div>
      </td>
      <td style="vertical-align: middle !important" v-if="!isMobile" data-th="Price">{{ cartItem.price }}</td>
      <td style="vertical-align: middle !important" data-th="Quantity">
        <input
          type="number"
          class="form-control text-center"
          :value="cartItem.quantity"
          :disabled="cartItem.id === 'free-roll'"
          @input="updateQuantity"
          @keydown="preventZero"
          min="1"
          required
        >
      </td>
      <td style="vertical-align: middle !important" data-th="Subtotal" class="text-center">${{ subtotal }}</td>
      <td style="vertical-align: middle !important" class="actions" data-th="">
        <button class="btn btn-danger btn-sm" @click="removeItem"><i class="fa fa-trash-o"></i></button>
      </td>
    </tr>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  
  export default {
    props: ['cartItem'],
    computed: {
      subtotal() {
        // Use parseInt to convert blank or undefined quantity to 0
        const quantity = parseInt(this.cartItem.quantity) || 0;
        return quantity * this.cartItem.price;
      },
      isMobile() {
        return window.innerWidth <= 768; // Define your threshold for mobile width
      }
    },
    methods: {
      ...mapActions(['updateCart', 'removeItemInCart']),
      removeItem() {
        let vm = this;
        this.removeItemInCart({
          item: vm.cartItem
        });
      },
      preventZero(event) {
        // Prevent the user from inputting 0 directly
        if (event.key === '0') {
          event.preventDefault();
        }
      },
      updateQuantity(event) {
        let quantity = event.target.value.trim(); // Trim leading and trailing spaces
  
        // If the input is blank, set quantity to 1
        if (quantity === '') {
          quantity = '1';
        } else {
          // If the input is 0, set quantity to 1
          const parsedQuantity = parseInt(quantity);
          if (parsedQuantity === 0 || isNaN(parsedQuantity)) {
            quantity = '1';
          }
        }
  
        // Update the cart with the new quantity
        this.updateCart({
          item: this.cartItem,
          quantity: parseInt(quantity),
          isAdd: false
        });
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-items: center;
  }
  </style>
  