<template>
<div class="about-me-container">
    <!-- <header class="masthead">
        <h1 class="masthead-heading"><p class="description">
            As an artist, I am passionate about creating a rich tapestry of experiences that resonate deeply. With dedication and craftsmanship, I have meticulously crafted a diverse range of artistic expressions. Allow me to offer you a glimpse into the world of beauty and luxury through meticulous presentation.
          </p></h1>
      </header> -->
    <section class="location-section">
        <h2>Origin</h2>
        <div class="content">
            <p>
                As a NYC native raised in the heart of Queens, I'm a true New Yorker through and through. This city has shaped me into a gritty, determined artisan who takes pride in my craft.
            </p>
            <p>
                This borough is more than just my home; it's the driving force behind my work. The diverse neighborhoods, the vibrant street culture, and the relentless determination of its residents have all left their mark on my creations.
            </p>
            <p>
                I approach my craft with the passion and dedication while navigating the city's challenges. Each roll is a testament to my commitment to excellence.
            </p>
        </div>
    </section>
    <!-- <section class="questions-section">
        <h2>Q&A</h2>
        <div class="qa-item">
            <h3>What inspired you?</h3>
            <p>Rolling is both my livelihood and my passion. I was inspired by the artistry and the opportunity to work with the community.</p>
        </div>
        <div class="qa-item">
            <h3>Why do you care so much about the people who buy your products?</h3>
            <p>Being a Queens local, I understand the importance of community support. I deeply appreciate those who choose my creations, and their satisfaction is my utmost priority.</p>
        </div>
    </section> -->
    <footer class="content-footer">
        <div class="content">
            <div class="footer-social-icons">
                <div class="btn2__container">
                    <a href="https://www.instagram.com/baker.bois/" target="_blank" class="btn2">
                        <i href="#">
                            <font-awesome-icon style="background: transparent; font-size: 30px;" :icon="['fab', 'instagram']" /></i>
                        <span>instagram</span>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</div>
</template>

  
<script>
export default {
    name: 'Profile',
};
</script>

  
<style lang="scss" scoped>
.content {
    height: 100%;
    font-size: 18px;
}

.btn {
    color: #333;
    border: 2px solid;
    border-radius: 3px;
    text-decoration: none;
    display: inline-block;
    padding: 5px 10px;
    font-weight: 600
}

body {
    font-family: 'Inter';
    color: #FFF;
    background: #322f30;
    -webkit-font-smoothing: antialiased;
}

a {
    text-decoration: none;
    color: #fff;
}

p>a:hover {
    color: #d9d9d9;
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 1% 0 1% 0;
}

._12 {
    font-size: 1.2em;
}

._14 {
    font-size: 1.4em;
}

ul {
    padding: 0;
    list-style: none;
}

.footer-social-icons {
    height: 100%;
    width: 350px;
    display: block;
    margin: 0 auto;
    text-align: center;
    opacity: 0.9 !important;
}

.social-icon {
    color: #fff;
}

ul.social-icons {
    margin-top: 10px;
}

.social-icons li {
    vertical-align: top;
    display: inline;
    height: 100px;
}

.social-icons a {
    color: #fff;
    text-decoration: none;
}

.fa-facebook {
    padding: 10px 14px;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    background-color: #322f30;
}

.fa-facebook:hover {
    background-color: #3d5b99;
}

.fa-twitter {
    padding: 10px 12px;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    background-color: #322f30;
}

.fa-twitter:hover {
    background-color: #00aced;
}

.fa-instagram {
    padding: 10px 14px;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    background-color: #322f3000;
}

.fa-rss:hover {
    background-color: #eb8231;
}

.fa-youtube {
    padding: 10px 14px;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    background-color: #322f30;
}

.fa-youtube:hover {
    background-color: #e64a41;
}

.fa-linkedin {
    padding: 10px 14px;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    background-color: #322f30;
}

.fa-linkedin:hover {
    background-color: #0073a4;
}

.fa-github {
    padding: 10px 14px;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    background-color: #322f30;
}

.fa-github:hover {
    background-color: #5a32a3;
}

header h1 {
    font-size: 40px;
    font-weight: 600;
    background-image: linear-gradient(to left, #553c9a, #b393d3);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

section:before {
    content: attr(data-description);
    font-size: 0.9rem;
    font-family: 'oswald';
    color: #fff;
    position: absolute;
    z-index: 20;
    left: 2rem;
    top: 2rem;
}

.btn2__container {
    height: 100vh;
    display: contents;
    justify-content: center;
    align-items: center;
}

.btn2 {
    min-width: 110px;
    background-color: #ffffff6e;
    border: 2px outset;
    border-radius: 100px;
    padding: 1rem 1rem;
    text-decoration: none;
    color: #584428;
    display: flex;
    transition: all .2s ease-in-out;
    margin-right: 10px;
    justify-content: center;
    line-height: normal;
    align-items: center;
    margin: auto;
    height: 55px;
    width: 165px;

    i {
        color: #DF3796;
        font-size: 20px;
        padding-right: 10px;
        transition: all .3s ease-in-out;
    }

    span {
        font-family: "Roboto", sans-serif;
        align-self: center;
        transform: translateX(0px);
        transition: all .1s ease-in-out;
        opacity: 1;
        font-weight: 500;
        font-size: 18px;
        margin-left: -15px;
        margin-right: 15px;
    }

    &:hover {
        transform: scale(1.1);
        background: linear-gradient(to right, #ff3019 0%, #c90477 100%);
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.10);

        i {
            transform: translateX(45px);
            padding-right: 0;
            color: #FFF;
        }

        span {
            transform: translateX(30px);
            opacity: 0;
        }
    }

    &:active {
        transform: scale(1);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.10);
    }
}

.btn2 i {
    color: #392c34;
}

.btn2:hover {
    border: 2px inset;
}

.btn-f {
    min-width: 110px;
    background-color: #fff;
    padding: 1rem 2rem;
    text-decoration: none;
    color: #286ED6;
    display: flex;
    transition: all .2s ease-in-out;

    i {
        color: #286ED6;
        font-size: 20px;
        padding-right: 10px;
        transition: all .3s ease-in-out;
    }

    span {
        font-family: "Roboto", sans-serif;
        align-self: center;
        transform: translateX(0px);
        transition: all .1s ease-in-out;
        opacity: 1;
    }

    &:hover {
        transform: scale(1.1);
        background-color: #286ED6;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.10);

        i {
            transform: translateX(45px);
            padding-right: 0;
            color: #FFF;
        }

        span {
            transform: translateX(30px);
            opacity: 0;
        }
    }

    &:active {
        transform: scale(1);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.10);
    }
}

@media (max-width: 576px) {
    .wrapper {
        max-width: 100%;
        width: 100%;
        position: relative;
        z-index: 10;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 50%;
        text-align: center;
    }

    .footer-social-icons {
        width: 100%;
        display: block;
        margin: 0 auto;
        text-align: center;
        opacity: 0.9 !important;
    }
}

/* Background Image */
.about-me-container {
    background-image: url('https://your-queens-background-image-url.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'Your-Preferred-Font', sans-serif;
    /* Replace with your chosen font */
    color: #333;
    text-align: center;
    padding: 2rem;
}

.masthead {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

/* Styles for various sections */
.masthead-heading {
    display: flex;
    font-size: 1.2em;
    color: #70394c;
    width: 75%;
    text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
    margin-bottom: 1rem;
}

.location-section,
.questions-section {
    height: 100%;
    max-width: 790px;
    /* Adjust the max-width as needed */
    margin: 0 auto;
    /* Center the sections horizontally */
    background-color: #ffffffb8;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.location-section h2,
.questions-section h2 {
    font-size: 2rem;
    color: #111111;
    /* Pink color */
    margin-bottom: 1rem;
    display: none;
}

.qa-item {
    margin-top: 1.5rem;
}

.social {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.social-link {
    text-decoration: none;
    color: #2196F3;
    /* Blue color */
    transition: color 0.3s ease;
}

.social-link:hover {
    color: #E91E63;
    /* Pink color on hover */
}
</style>
