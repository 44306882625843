// Correctly import only the needed Firebase modules using the new modular SDK syntax
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
// Optionally import getStorage if you need Firebase Storage
import { getStorage } from 'firebase/storage';

// Firebase configuration
const config = {
  apiKey: "AIzaSyC4RJQvD7U__qkSdzsMmiCI8wrUWrh69_A",
  authDomain: "bakerbois.com",
  databaseURL: "https://baker-bois-default-rtdb.firebaseio.com/",
  storageBucket: "baker-bois.appspot.com",
};

// Initialize Firebase
const app = initializeApp(config);

// Export initialized Firebase Auth and Database services
export const auth = getAuth(app);
export const database = getDatabase(app);
// Optionally export initialized Firebase Storage service
// export const storage = getStorage(app);

// Export GoogleAuthProvider for use in authentication methods
export const provider = new GoogleAuthProvider();

export const storage = getStorage(app);

// Firebase Listener for Auth State Changes
// This can be used directly where needed or you can setup a more global auth state observer
export const firebaseListener = (func) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      func(true, user);
    } else {
      func(false);
    }
  }, (error) => {
    console.log(error);
  });
};
