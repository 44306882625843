import { auth, database } from '../config/firebaseConfig';
import {
  createUserWithEmailAndPassword,
  signOut,
  signInWithEmailAndPassword
} from 'firebase/auth';
import {
  ref as dbRef,
  set,
  child,
  get,
  onValue,
  push,
  update
} from 'firebase/database';

export const updateCart = ({ commit }, { item, quantity, isAdd }) => {
  commit('UPDATE_CART', { item, quantity, isAdd });
  if (isAdd) {
    let message_obj = {
      message: `${item.title} added to cart successfully`,
      messageClass: 'success',
      autoClose: true,
    };
    commit('ADD_MESSAGE', message_obj);
  }
};

export const removeItemInCart = ({ commit }, { item }) => {
  commit('REMOVE_CART_ITEM', { item });
};

export const registerByEmail = async (_, { name, email, password, phoneNumber }) => {
  const response = await createUserWithEmailAndPassword(auth, email, password);
  const user = response.user;

  await set(child(dbRef(database), `users/${user.uid}`), {
    name,
    email,
    phoneNumber,
    points: 0,
  });

  const userDataSnap = await get(child(dbRef(database), `users/${user.uid}`));
  return {
    ...userDataSnap.val(),
    uid: user.uid,
  };
};

export const applyDiscount = ({ commit }, pointsToRedeem) => {
  commit('APPLY_DISCOUNT', pointsToRedeem);
};

export const updatePoints = async (_, { pointsToRedeem }) => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    console.error("No user is logged in");
    return;
  }

  const userRef = child(dbRef(database), `users/${currentUser.uid}`);
  const snapshot = await get(userRef);
  let userData = snapshot.val();

  if (!userData || userData.points === undefined) {
    console.error('Invalid user data or points not found');
    return;
  }

  const numericPointsToRedeem = parseInt(pointsToRedeem, 10);
  if (isNaN(numericPointsToRedeem)) {
    console.error('Points to redeem is not a valid number');
    return;
  }

  const currentPoints = parseInt(userData.points, 10);
  if (isNaN(currentPoints)) {
    console.error('Current user points is not a valid number');
    return;
  }

  const newPoints = Math.max(0, currentPoints - numericPointsToRedeem);
  await update(userRef, { points: newPoints });
};

export const fetchUserData = async ({ commit }) => {
  const currentUser = auth.currentUser;
  if (currentUser) {
    const userRef = child(dbRef(database), `users/${currentUser.uid}`);
    const snapshot = await get(userRef);
    let userData = snapshot.val();
    commit('UPDATE_USER_DATA', userData);
    return { ...userData, uid: currentUser.uid };
  } else {
    console.log('No user is logged in');
    return null;
  }
};

export const logout = async ({ commit }) => {
  await signOut(auth); // Perform the sign-out operation with Firebase Auth
  commit('SET_CART', []); // Clear the current cart
  commit('AUTH_STATUS_CHANGE', null); // Update auth status to reflect logout
  commit('UPDATE_USER_DATA', null); // Clear user data upon logout
};

export function loginWithEmail({ dispatch }, { email, password }) {
  return signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // signInWithEmailAndPassword updates the auth state, so you should now fetch and commit user data.
      // Dispatch fetchUserData to ensure user data is immediately available after login.
      await dispatch('fetchUserData');
      // Return user data for any post-login actions if needed.
      return { uid: userCredential.user.uid, email: userCredential.user.email };
    }).catch(error => {
      console.error("Login failed:", error);
      throw error; // Ensure errors are caught where loginWithEmail is called.
    });
}

export function listenToProductList({ commit }) {
  const productListRef = child(dbRef(database), '1adTQ512s0HkBRmzumb5GKqfkXB0mClz1ekMkmTmQ0EY/products');
  onValue(productListRef, (snapshot) => {
    commit('UPDATE_PRODUCT_LIST', snapshot.val());
  });
}

export function listenToTransactionList({ commit }) {
  const transactionListRef = child(dbRef(database), '1adTQ512s0HkBRmzumb5GKqfkXB0mClz1ekMkmTmQ0EY/transactions');
  onValue(transactionListRef, (snapshot) => {
    let transactions = {};
    snapshot.forEach((childSnapshot) => {
      transactions[childSnapshot.key] = childSnapshot.val();
    });
    commit('UPDATE_TRANSACTION_LIST', transactions);
  });
}

export function getShoppingCart({ commit }, { uid }) {
  if (uid) {
    const cartRef = child(dbRef(database), `1adTQ512s0HkBRmzumb5GKqfkXB0mClz1ekMkmTmQ0EY/cart/${uid}`);
    get(cartRef).then((snapshot) => {
      if (snapshot.exists()) {
        commit('SET_CART', snapshot.val());
      } else {
        commit('SET_CART', []);
      }
    });
  }
}

export function saveShoppingCart(_, { uid, cartItemList }) {
  const cartRef = child(dbRef(database), `1adTQ512s0HkBRmzumb5GKqfkXB0mClz1ekMkmTmQ0EY/cart/${uid}`);
  return set(cartRef, cartItemList);
}

export function saveToTransaction(_, { uid, cartItemList, date, status }) {
  const transactionsRef = child(dbRef(database), `transactions/${uid}`); // Update the path according to your database structure
  const newTransactionKey = push(transactionsRef).key;
  let newTransaction = {};
  // Include cartItemList, date, and status in the transaction object
  newTransaction[`/transactions/${uid}/${newTransactionKey}`] = {
    items: cartItemList, // Assuming cartItemList is an array of items
    date: date, // ISO string format date
    status: status, // ACTIVE or any other status
  };
  return update(dbRef(database), newTransaction);
}