<template>
<div :class="(showMessageBar?'':'d-none') + '  message-bar'">
    <div :class="messageClass" style="border-radius: 7px; ">
        <Button bsStyle='link' class="btn btn-link close-btn" @click="closeMessageBar">
            <i class="fa fa-times"></i>
        </Button>
        <div style="font-weight: 700; text-align: center;">{{ currentMessage }}</div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex';
export default {
    computed: {
        ...mapGetters(['messages']),
        showMessageBar() {
            return this.messages.message && this.messages.message.length > 0;
        },
        messageClass() {
            let mgClass = this.messages.messageClass;
            return "col-12 panel panel-" + mgClass + " " + (this.showMessageBar ? "" : "hidden");
        },
        currentMessage() {
            return this.messages.message;
        }
    },
    methods: {
        ...mapActions(['clearMessage']),
        closeMessageBar() {
            this.clearMessage();
        }
    }

}
</script>

<style scoped>
.message-bar {
    position: fixed;
    top: 0;
    left: 30%;
    right: 30%;
    min-height: 50px;
    z-index: 9999;
}

.message-bar .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: #FFF;
}

.message-bar .panel {
    border-color: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-right: 30px;
}

.panel {
    padding: 10px 15px;
}

.panel-danger {
    background-color: #d9544fb9;
    color: #FFF;
}

.panel-success {
    background-color: #5cb85cb8;
    color: #FFF;
}

.panel-warning {
    background-color: #d395389e;
    color: #FFF;
}

@media (max-width: 768px) {
  .message-bar {
    position: fixed;
    top: 0;
    left: 20%;
    right: 20%;
    min-height: 50px;
    z-index: 9999;
}
}
</style>
