<template>
    <div class="register-container">
      <form id="register-form" @submit.prevent="onSubmit">
        <div class="form-group">
          <h3 class="register-title">Register</h3>
        </div>
        <div class="form-group">
          <input type="text" name="name" id="name" class="form-control" placeholder="Full Name" v-model="name" required />
        </div>
        <div class="form-group">
          <input type="email" name="email" id="email" class="form-control" placeholder="Email Address" v-model="email" required />
        </div>
        <div class="form-group">
          <input type="password" name="password" id="password" class="form-control" placeholder="Password" v-model="password" required />
        </div>
        <div class="form-group">
          <input type="tel" name="phoneNumber" id="phoneNumber" class="form-control" placeholder="Phone Number" v-model="phoneNumber" required />
        </div>
        <div class="form-group">
          <button type="submit" class="btn register-btn" :class="{ 'loading': isLoading }">
            <span v-if="isLoading" class="loader"></span>
            Register
          </button>
        </div>
        <div class="form-group">
          <div class="text-center">
            Already a member? You can
            <router-link to="/login">
              <a class="login-link">Login here</a>
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      name: '',
      email: '',
      password: '',
      phoneNumber: '',
      isLoading: false
    };
  },
  methods: {
    ...mapActions(['addMessage', 'clearMessage', 'registerByEmail']),
    onSubmit() {
      this.isLoading = true;
      let data = {
        name: this.name,
        email: this.email,
        password: this.password,
        phoneNumber: this.phoneNumber,
      };
      this.registerByEmail(data)
        .then(() => {
          // Success action
          this.clearMessage();
          this.$router.push({ name: 'mainpage' });
        })
        .catch(error => {
          // Error action
          let message_obj = {
            message: error.message,
            messageClass: 'danger',
            autoClose: true
          };
          this.addMessage(message_obj);
        })
        .then(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
  
  <style scoped>
  /* Container */
  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 89vh;
    background-image: linear-gradient(to bottom right, #7b7eff, #ff7575);
    background-size: cover;
  }
  
  /* Form */
  #register-form {
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 15px;
    padding: 40px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 100%;
    max-width: 500px;
    animation: fadeIn 1s ease;
  }
  
  /* Title */
  .register-title {
    font-size: 36px;
    color: #333;
    margin-bottom: 30px;
    font-weight: bold;
  }
  
  /* Input Fields */
  .form-group {
    margin-bottom: 30px;
  }
  
  .form-control {
    width: 100%;
    padding: 20px;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    background-color: #f5f5f5;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .form-control:focus {
    background-color: #eaeaea;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  }
  
  /* Button */
  .register-btn {
    background-color: #454a50;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    overflow: hidden;
    letter-spacing: 1px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  
  .register-btn:hover {
    background-color: #3e3e3e;
    transform: scale(1.05);
    color: #d0d0d0;
  }
  
  .loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #333;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Link */
  .login-link {
    color: #44586d;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    font-weight: bold;
  }
  
  .login-link:hover {
    color: #333639;
  }
  
  /* Animations */
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .register-container {
      min-height: 84vh;
    }
  }
  </style>
  