<template>
    <div class="login-container" style="overflow:hidden;">
      <form id="login-form" @submit.prevent="onSubmit">
        <div class="form-group">
          <h3 class="login-title">Welcome Back!</h3>
        </div>
        <div class="form-group">
          <input type="email" name="email" id="email" class="form-control" placeholder="Email Address" v-model="email" required />
        </div>
        <div class="form-group">
          <input type="password" name="password" id="password" class="form-control" placeholder="Password" v-model="password" required />
        </div>
        <div class="form-group">
          <button type="submit" class="btn login-btn" :class="{ 'loading': isLoading }">
            <span v-if="isLoading" class="loader"></span>
            Log in
          </button>
        </div>
        <div class="form-group">
          <div class="text-center">
            Don't have an account? 
            <router-link to="/register">
              <a class="register-link">Sign Up</a>
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  
  export default {
    data() {
      return {
        email: '',
        password: '',
        isLoading: false
      };
    },
    methods: { 
      ...mapActions(['addMessage', 'clearMessage', 'loginWithEmail']),
      onSubmit() {
        this.isLoading = true;
        let data = {
          email: this.email,
          password: this.password
        };
        this.loginWithEmail(data)
          .then(() => {
            this.clearMessage();
            this.$router.push({
              name: 'mainpage'
            });
          })
          .catch(error => {
            let message_obj = {
              message: error.message,
              messageClass: 'danger',
              autoClose: true
            };
            this.addMessage(message_obj);
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    }
  };
  </script>
  
  <style scoped>
  /* Container */
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 89vh;
    background-image: radial-gradient(circle at 50% 50%, #ff6b6b, #6078ea);
  }
  
  /* Form */
  #login-form {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 100%;
    max-width: 400px;
    animation: fadeIn 1s ease;
  }
  
  /* Title */
  .login-title {
    font-size: 36px;
    color: #333;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  /* Input Fields */
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-control {
    width: 100%;
    padding: 18px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .form-control:focus {
    background-color: #fff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  }
  
  /* Button */
  .login-btn {
    background-color: #454a50;
    color: #fff;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    overflow: hidden;
    letter-spacing: 1px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  
  .login-btn:hover {
    background-color: #3e3e3e;
    transform: scale(1.05);
    color: #d0d0d0;
  }
  
  .loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #333;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Link */
  .register-link {
    color: #44586d;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    font-weight: bold;
  }
  
  .register-link:hover {
    color: #333639;
  }
  
  /* Animations */
  @keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  }

  @media (max-width: 768px) {
    .login-container {
        min-height: 84vh;
    }
}


  </style>
  