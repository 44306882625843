<template>
<div>
    <dynamic-background></dynamic-background>
    <div class="hero-section cityWallpaperBackground">
        <div class="hero-content">
            <div class="graffiti-text" style="margin-bottom: 124px;">
                <span v-for="(letter, index) in text" :key="index" class="graffiti-letter">{{ letter }}</span>
            </div>

            <footer class="content-footer">
                <div class="content">
                    <div class="footer-social-icons">
                        <div class="btn2__container">
                            <a href="https://www.instagram.com/baker.bois/" target="_blank" class="btn2">
                                <i href="#">
                                    <font-awesome-icon style="background: transparent; font-size: 30px;" :icon="['fab', 'instagram']" /></i>
                                <span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>

    <div class="et_pb_section et_pb_with_background et_section_regular" style="background: linear-gradient(to bottom, #ffffff, #ffffff00);">
        <div class="et_pb_row et_pb_row_7">
            <div class="et_pb_column et_pb_column_1_2">
                <div class="et_pb_text et_pb_text_align_left et_pb_bg_layout_dark">
                    <div class="et_pb_text_inner">
                        <h6 style="text-align: center;">About BakerBois</h6>
                        <h2>Premium Rolling</h2>
                        <p class="about-details">BakerBois takes pride in delivering top-tier products from New York to exclusive destinations. Our creations offer an unparalleled experience.</p>
                        <p class="about-details">I approach my craft with passion and dedication while navigating the city's challenges. Each roll is a testament to my commitment to excellence.</p>
                    </div>
                    <EventBooking />
                </div>
            </div>
        </div>
    </div>

    <!-- Product Section with Loading State -->
    <div class="container" :class="{ loadingItem: isProductLoading }">
        <div class="row text-center" v-if="isProductLoading">
            <grid-loader :loading="isProductLoading" :color="loaderColor" :size="loaderSize"></grid-loader>
        </div>
        <div class="row" v-if="!isProductLoading">
            <app-product-item v-for="prod in sortedProducts" :item="prod" :key="prod.itemnum" :displayList="displayList"></app-product-item>
        </div>
    </div>

    <!-- Our Commitment to Quality Section -->
    <div class="et_pb_section natureWallpaperBackground" style="margin-top: -9px;">
        <div class="et_pb_row">
            <div class="et_pb_column et_pb_column_4_4">
                <div class="et_pb_text et_pb_bg_layout_light">
                    <div class="et_pb_text_inner">
                        <h2>Our Commitment to Excellence</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="et_pb_row">
            <div class="et_pb_column et_pb_column_1_2 grid-control about-details">
                <div class="et_pb_blurb et_pb_bg_layout_light">
                    <div class="et_pb_blurb_content">
                        <div class="et_pb_main_blurb_image">
                            <span class="et_pb_image_wrap"><img src="@/assets/images/open.png" alt="" width="150" height="150"></span>
                        </div>
                        <div class="et_pb_blurb_container">
                            <h4>Open 7 Days</h4>
                            <div class="et_pb_blurb_description">
                                <p>For your convenience, we're open 7 days a week</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="et_pb_column et_pb_column_1_2 grid-control about-details">
                <div class="et_pb_blurb et_pb_bg_layout_light">
                    <div class="et_pb_blurb_content">
                        <div class="et_pb_main_blurb_image">
                            <span class="et_pb_image_wrap"><img src="@/assets/images/quality.png" alt="" width="200" height="200"></span>
                        </div>
                        <div class="et_pb_blurb_container">
                            <h4>Quality</h4>
                            <div class="et_pb_blurb_description">
                                <p>We offer only top quality products.<br>Our professional staff is here to help you anytime.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="et_pb_row">
            <div class="et_pb_column et_pb_column_1_2 grid-control about-details">
                <div class="et_pb_blurb et_pb_bg_layout_light">
                    <div class="et_pb_blurb_content">
                        <div class="et_pb_main_blurb_image">
                            <span class="et_pb_image_wrap"><img src="@/assets/images/delivery.png" alt="" width="200" height="200"></span>
                        </div>
                        <div class="et_pb_blurb_container">
                            <h4>Deliver to Your Door</h4>
                            <div class="et_pb_blurb_description">
                                <p>We deliver orders from $80 (depending on your location) and offer local delivery services, with shipping available to select states.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="et_pb_column et_pb_column_1_2 grid-control about-details">
                <div class="et_pb_blurb et_pb_bg_layout_light">
                    <div class="et_pb_blurb_content">
                        <div class="et_pb_main_blurb_image">
                            <span class="et_pb_image_wrap"><img src="@/assets/images/applepay.png" alt="" width="200" height="200"></span>
                        </div>
                        <div class="et_pb_blurb_container">
                            <h4>Accepting Apple Pay</h4>
                            <div class="et_pb_blurb_description">
                                <p>No cash? No worries. Use Apple Pay for a secure checkout!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="et_pb_row">
                <div class="et_pb_column et_pb_column_1_2 grid-control2" style="margin-top: 20px;">
                    <div class="et_pb_slider et_pb_bg_layout_dark">
                        <div class="et_pb_slides">
                            <div class="et_pb_slider_container_inner">
                                <div class="et_pb_slide_description">
                                    <h4 class="et_pb_slide_title">Best cannagar ever</h4>
                                    <div class="et_pb_slide_content" style="color: #452222;">
                                        <p>The single roll shot me to the moon so I had to take it further with some hashies. Totally am coming back!</p>
                                        <p>– cablanasian</p>
                                    </div>
                                </div>
                                <!-- More slides could be added here -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import {
    mapGetters,
    mapState,
    mapActions
} from 'vuex'
import ProductItem from './product/ProductItem.vue';
import EventBooking from './EventBooking.vue';
import GridLoader from 'vue-spinner/src/GridLoader.vue';
import 'vueperslides/dist/vueperslides.css';
import $ from 'jquery';
import DynamicBackground from './DynamicBackground.vue';
// Import the required Firebase modules
import {
    getDatabase,
    ref as dbRef,
    onValue
} from 'firebase/database';
import {
    app
} from '../config/firebaseConfig';

export default {
    data() {
        return {
            loaderColor: "#e3e3e3",
            loaderSize: "50px",
            displayList: false,
            text: ["Discover the Art", " of Infusion"],
            pastelColors: ['#ffb3ba', '#ffdfba', '#ffffba', '#baffba', '#baffff', '#9f9fff'],
            groupedTransactions: {}, // Initialize as an empty object to group transactions
            users: [],
        }
    },
    mounted() {
        // Existing mounted logic...

        // Initialize the database service
        const database = getDatabase(app);

        // Correctly reference the "users" path and listen for value changes
        const usersRef = dbRef(database, "users");
        onValue(usersRef, (snapshot) => {
            this.users = snapshot.val();
        });

        // Reference a specific path and listen for value changes
        const transactionsRef = dbRef(database, "1adTQ512s0HkBRmzumb5GKqfkXB0mClz1ekMkmTmQ0EY/transactions");
        onValue(transactionsRef, (snapshot) => {
            const groupedTransactions = {};

            snapshot.forEach((userSnapshot) => {
                const userId = userSnapshot.key;
                const userTransactions = userSnapshot.val();

                groupedTransactions[userId] = [];

                for (const transactionId in userTransactions) {
                    if (Object.prototype.hasOwnProperty.call(userTransactions, transactionId)) {
                        const transactionData = userTransactions[transactionId];
                        groupedTransactions[userId].push({
                            transactionId: transactionId,
                            ...transactionData,
                        });
                    }
                }
            });

            this.groupedTransactions = groupedTransactions;
        });

    },
    created() {
        function parallax_height() {
            var scroll_top = $(this).scrollTop();
            var header_height = $(".sample-header-section").outerHeight();
            $(".sample-section").css({
                "margin-top": header_height
            });
            $(".sample-header").css({
                height: header_height - scroll_top
            });
        }
        parallax_height();
        $(window).scroll(function () {
            parallax_height();
        });
        $(window).resize(function () {
            parallax_height();
        });
    },
    computed: {
        ...mapGetters(['products', 'isProductLoading', 'currentUser', 'isLoggedIn', ]),
        ...mapState(['filteredTransactions']),
        filteredTransactions() {
            // Get transactions of the current user only
            // const currentUserTransactions = this.groupedTransactions[this.currentUser.uid] || [];
            return this.groupedTransactions[this.currentUser.uid]

            // Filter out blank items for each transaction
            // return currentUserTransactions.map(transaction => {
            //     return {
            //         ...transaction,
            //         items: transaction.items.filter(item => item.title || item.price !== undefined)
            //     };
            // });
        },
        sortedProducts() {
            // Ensure products is defined and filter out any null elements
            const filteredProducts = this.products && this.products.filter(product => product !== null) || [];

            // Return early if products are not available or empty
            if (filteredProducts.length === 0) {
                return [];
            }

            // Determine whether to hide the "free-roll" product
            let hideFreeRoll = true;

            // Filter out the "free-roll" product if necessary
            const productsWithoutFreeRoll = hideFreeRoll ? filteredProducts.filter(product => product.id !== "free-roll") : filteredProducts;

            return productsWithoutFreeRoll.slice().sort((a, b) => a.itemnum - b.itemnum);
        }

    },
    components: {
        appProductItem: ProductItem,
        GridLoader,
        DynamicBackground,
        EventBooking
    },
    methods: {
        ...mapActions(['fetchUserData']), // Add fetchUserData to mapped actions
        changeDisplay(isList) {
            this.displayList = isList;
        },
        async fetchData() {
            // Call fetchUserData and wait for it to complete
            await this.fetchUserData();
        }
    }
}
</script>

<style lang="scss" scoped>
.about-details {
    text-align: center;
    background: #ffffffad;
    z-index: 9;
    position: relative;
    border: 2px groove #ffffff40;
    border-radius: 15px;
    padding: 10px;
}

.grid-control {
    flex-basis: 30% !important;
}

.grid-control2 {
    flex-basis: 60% !important;
}

.graffiti-text {
    position: absolute;
    /* Use Bubblegum Sans font */
    text-transform: uppercase;
    /* Convert text to uppercase for consistent appearance */
}

.graffiti-letter {
    font-size: 60px;
    /* Increase font size for thickness */
    font-weight: 1000;
    /* Make text thicker */
    line-height: 1;
    margin-right: 10px;
    animation: graffitiAnimation 6s infinite alternate;
    border-radius: 10px;
    /* Round corners */
    display: inline-block;
    /* Rotate letters slightly for irregularity */
    position: relative;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    /* Add a text shadow */
}

@keyframes graffitiAnimation {
    0% {
        color: #fffffff4;
        /* Default color */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        /* Default text shadow */
    }

    50% {
        color: #ffededf2;
        /* Change color */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        /* Change text shadow */
    }

    100% {
        color: #fffffff4;
        /* Back to default color */
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        /* Default text shadow */
    }
}

.cityWallpaperBackground {
    background-image: url('@/assets/images/citywallpaper.gif'), linear-gradient(to bottom, rgba(30, 4, 4, 0), rgba(18, 1, 1, 0.5));
    // background-size: cover;
    // background-position: center;  
    background-repeat: no-repeat;
    background-size: 100% 521px;
}

.cityWallpaperBackground::before,
.cityWallpaperBackground::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0px;
    /* Adjust this value to change the width of the gradient border */
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
}

.cityWallpaperBackground::before {
    left: 0;
}

.cityWallpaperBackground::after {
    right: 0;
}

::v-deep .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    -webkit-box-pack: center;
    justify-content: space-around;
}

::v-deep .mb-3,
.my-3 {
    margin-bottom: 0 !important;
}

::v-deep .card {
    border-radius: 1.25rem;
}

::v-deep .card-body {
    height: 166px;
    margin-top: -15px;
}

.iframe-control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.natureWallpaperBackground {
    background-image: url('@/assets/images/naturewallpaper.jpg'), linear-gradient(to bottom, rgba(30, 4, 4, 0), rgba(18, 1, 1, 0.5));
    ;
    // background-size: cover;
    // background-position: center;  
    background-repeat: no-repeat;
    background-size: 100% 950px;
}

.natureWallpaperBackground::before,
.natureWallpaperBackground::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0px;
    /* Adjust this value to change the width of the gradient border */
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
}

.natureWallpaperBackground::before {
    left: 0;
}

.natureWallpaperBackground::after {
    right: 0;
}

.et_pb_section {
    background-color: #333;
    /* Dark background */
    padding: 50px 0;
}

.et_pb_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
}

.et_pb_column {
    flex-basis: 50%;
    padding: 0 15px;
    z-index: 9;
}

.et_pb_button {
    display: inline-block;
    background-color: #007bff;
    /* Blue button */
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
}

.et_pb_slider_container_inner {
    background-color: #ffffffcf;
    /* Slightly lighter dark bg for contrast */
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
    border: 2px groove white;
}

.et_pb_slide_description {
    text-align: center;
}

.et_pb_slide_title {
    color: #474e3f;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .et_pb_column {
        flex-basis: 100%;
    }
}

.et_pb_section {
    background-color: #ffffff;
    /* or any color you prefer */
    padding: 35px 0;
}

.et_pb_row {
    display: flex;
    justify-content: center;
    z-index: 9;
    position: relative;
}

.et_pb_blurb {
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    height: 315px;
}

.et_pb_main_blurb_image img {
    margin-bottom: 20px;
    width: 100px;
    /* Adjust based on preference */
    height: auto;
}

.et_pb_blurb_container h4 {
    margin-bottom: 15px;
    color: #282828;
    /* or any color you prefer */
    font-weight: 500;
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
}

.et_pb_blurb_description p {
    color: #666666;
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    /* or any color you prefer */
}

.et_pb_text_inner h2 {
    margin-bottom: 30px;
    font-family: Open Sans, Arial, sans-serif;
    font-weight: 600;
    color: #1d1c1c;
    margin-left: 20px;
    text-align: center;
    white-space: nowrap;
}

.hero-section {
    position: relative;
    width: 100%;
    height: 46vh;
    overflow: hidden;
}

.hero-content {
    position: absolute;
    top: 95px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    /* Ensure it's above the video */
    color: #fff;
    padding: 15px;
    box-sizing: border-box;
    /* Adjust text color as necessary */
}

.hero-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: -1;
    /* Ensure video stays in the background */
}

/* Ensure video covers the area, consider different aspect ratios */
@media (min-aspect-ratio: 16/9) {
    .hero-video {
        width: 100%;
        height: auto;
    }
}

.quality-section {
    padding: 60px 20px;
    background-color: #fff;
    text-align: center;
}

.quality-section h2 {
    margin-bottom: 25px;
}

.quality-section p {
    margin-bottom: 30px;
}

.quality-features {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.quality-feature {
    max-width: 300px;
    margin: 15px;
}

.quality-feature h3 {
    color: #333;
}

.quality-feature p {
    font-size: 14px;
}

.feature-section {
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
    margin: 20px 0;
}

.feature-section h2 {
    font-weight: bold;
    margin-bottom: 15px;
}

.feature-section p {
    margin-bottom: 20px;
}

.feature-buttons a.feature-btn {
    display: inline-block;
    margin: 0 10px;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

.feature-buttons a.feature-btn:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {

    .grid-control {
        flex-basis: 100% !important;
    }

    .grid-control2 {
        flex-basis: 100% !important;
    }

    .btn2 {
        height: 45px;
        width: 155px;
    }

    .graffiti-text {
        text-align: center;
        padding: 0 15px;
    }

    .graffiti-letter {
        font-size: 34px;
        text-align: center;
        font-weight: 1000;
        margin-right: 6px;
    }

    .cityWallpaperBackground[data-v-539f8c61] {
        background-size: 100% 245px;
    }

    .et_pb_text_inner h2 {
        white-space: unset;
        text-align: center;
    }

    .iframe-control {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    // .hero-section,
    // .quality-section,
    // .product-section {
    //     padding: 20px;
    // }

    .title {
        font-size: 2.5rem;
        margin-bottom: 9rem !important;
    }

    .quality-features {
        flex-direction: column;
        /* Stack features on smaller screens */
        align-items: center;
    }
}

.product-section {
    padding: 2rem 0;
}

.information-section {
    background-color: #f4f4f4;
    padding: 3rem 1rem;
    text-align: center;
}

.info-title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.info-content {
    font-size: 1rem;
    max-width: 600px;
    margin: auto;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 45px;
}

.section {
    background-size: cover;
    background-position: center;
    padding-top: 15px;
    text-align: center;
    color: #333;
    margin-bottom: 25px;
}

.title {
    margin-bottom: 9.5rem;
    color: #fcfcfc;
    font-size: 4rem;
    font-weight: 800;
    text-align: center;
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    z-index: 10;
    position: absolute;
}

.content-footer {
    width: 100%;
    /* Ensures footer spans the width of the hero section */
    text-align: center;
    /* Centers footer content */
    /* Add additional styling as necessary */
}

.description {
    font-size: 1.2rem;
    font-style: italic;
    line-height: 1.6;
    width: 70%;
    background: #ffffff45;
}

.btn {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #da5890;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1.2rem;
    transition: background-color 0.3s ease-in-out;
}

.btn i {
    font-size: 1.5rem;
    margin-right: 10px;
}

.btn:hover {
    background-color: #6a2947;
}

.btn2__container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 85px;
}

::v-deep .col-md-4 {
    flex: 1 1 25%;
}

.btn2 {
    animation: graffitiAnimation 6s infinite alternate;
    background-color: #3c3c3c5c;
    border: 1px outset;
    border-radius: 15px;
    padding: 11px 11px;
    text-decoration: none;
    // color: #433c35;
    transition: all .2s ease-in-out;
    margin-right: 10px;
    justify-content: center;
    line-height: normal;
    align-items: center;
    margin: auto;
    height: 55px;
    width: 62px;

    i {
        // color: #433c35;
        font-size: 20px;
        transition: all .3s ease-in-out;
    }

    span {
        font-family: 'Inter';
        align-self: center;
        transform: translateX(0px);
        transition: all .1s ease-in-out;
        opacity: 1;
        font-weight: 600;
        font-size: 16px;
        margin-left: -15px;
        margin-right: 15px;
    }

    &:hover {
        transform: scale(1.1);
        background: linear-gradient(to right, #ffa7193c 0%, #c96004 100%);
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.10);

        i {
            transform: translateX(45px);
            padding-right: 0;
            color: #FFF;
        }

        span {
            transform: translateX(30px);
            opacity: 0;
        }
    }

    &:active {
        transform: scale(1);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.10);
    }
}

.btn2 i {
    animation: graffitiAnimation 6s infinite alternate;
}

.btn2:hover {
    border: 1px inset;
}

@media (max-width: 767.98px) {

    .svg-inline--fa {
        display: var(--fa-display, inline-block);
        height: 25px;
        overflow: visible;
        vertical-align: -0.125em;
    }

    .btn2 {
        height: 40px;
        width: 45px;
        padding: 6px 11px;

        span {
            font-size: 12px;
        }
    }

    .btn2__container {
        margin-top: -5px;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .hero-section {
        position: relative;
        width: 100%;
        height: 26vh;
        overflow: hidden;
    }

    .section {
        padding-top: 90px;
        margin-bottom: -80px;
    }

    .title {
        font-size: 45px;
    }

    .col-md-4 {
        flex: unset;
    }

}
</style>
