<template>
    <div ref="background" class="hero-background"></div>
    </template>
    
    <script>
    import * as THREE from 'three';
    
    export default {
        mounted() {
            this.initThreeJSBackground();
        },
        beforeDestroy() {
            this.cleanupThreeJS();
        },
        methods: {
            initThreeJSBackground() {
                // Clean up existing scene before initializing a new one
                if (this.scene) {
                    this.cleanupThreeJS();
                }
    
                this.scene = new THREE.Scene();
                this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
                this.renderer = new THREE.WebGLRenderer({
                    alpha: true // Ensure background is transparent
                });
                this.renderer.setSize(window.innerWidth, window.innerHeight);
                this.$refs.background.appendChild(this.renderer.domElement);
    
                const particlesGeometry = new THREE.BufferGeometry();
                const particlesCnt = 500; // Adjusted for example, customize as needed
                const posArray = new Float32Array(particlesCnt * 3);
    
                for (let i = 0; i < particlesCnt * 3; i++) {
                    posArray[i] = (Math.random() - 0.5) * 5; // Custom range, adjust as needed
                }
    
                particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));
    
                const particlesMaterial = new THREE.PointsMaterial({
                    size: 0.025,
                    color: '#FF7E00',
                    transparent: true,
                    opacity: 0.6,
                });
    
                this.particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial);
    
                this.scene.add(this.particlesMesh);
                this.camera.position.z = 3;
    
                const animate = () => {
                    if (!this.renderer) return; // Stop the animation loop if the renderer has been disposed
                    requestAnimationFrame(animate);
                    this.particlesMesh.rotation.y += 0.001;
                    this.renderer.render(this.scene, this.camera);
                };
    
                animate();
    
                // Context loss and restoration handling
                this.onContextLost = (event) => {
                    event.preventDefault();
                    console.log('WebGL context lost. Please reload the page.');
                };
                this.onContextRestored = () => {
                    console.log('WebGL context restored. Re-initializing the renderer...');
                    this.initThreeJSBackground();
                };
                this.renderer.domElement.addEventListener('webglcontextlost', this.onContextLost, false);
                this.renderer.domElement.addEventListener('webglcontextrestored', this.onContextRestored, false);
    
                // Resize handling
                this.onWindowResize = () => {
                    if (!this.camera || !this.renderer) return; // Check if disposed
                    this.renderer.setSize(window.innerWidth, window.innerHeight);
                    this.camera.aspect = window.innerWidth / window.innerHeight;
                    this.camera.updateProjectionMatrix();
                };
                window.addEventListener('resize', this.onWindowResize, false);
            },
            cleanupThreeJS() {
                // Cancel animation frame here if you have a reference to it
                if (this.renderer) {
                    this.$refs.background.removeChild(this.renderer.domElement);
                    this.renderer.dispose();
                    this.renderer = null;
                }
                if (this.scene) this.scene = null;
                if (this.camera) this.camera = null;
                if (this.particlesMesh) {
                    this.particlesMesh.geometry.dispose();
                    this.particlesMesh.material.dispose();
                    this.particlesMesh = null;
                }
                window.removeEventListener('resize', this.onWindowResize, false);
                if (this.renderer && this.renderer.domElement) {
                    this.renderer.domElement.removeEventListener('webglcontextlost', this.onContextLost, false);
                    this.renderer.domElement.removeEventListener('webglcontextrestored', this.onContextRestored, false);
                }
            }
        },
    };
    </script>
    
    <style>
    .hero-background {
        position: fixed;
        top: 220px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    </style>
    