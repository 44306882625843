import { auth, database } from '../../config/firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { ref as dbRef, get } from 'firebase/database';

const state = {
  isLoggedIn: false,
  user: null,
  isUserDataLoaded: false,
};

const mutations = {
  'AUTH_STATUS_CHANGE' (state, user) {
    state.isLoggedIn = !!user;
    state.user = user;
    state.isUserDataLoaded = false;
  },
  'UPDATE_USER_DATA' (state, userData) {
    if (userData) {
      state.user = {
        ...state.user,
        ...userData,
      };
      state.isUserDataLoaded = true;
    } else {
      // If userData is null (e.g., on logout), reset the user state
      state.user = null;
      state.isUserDataLoaded = false; // Reset flag on user data clear
    }
  }
};

const actions = {
  initAuth({ commit }) {
    onAuthStateChanged(auth, async user => {
      commit('AUTH_STATUS_CHANGE', user);
      if (user) {
        // User is signed in, fetch additional user data
        const userRef = dbRef(database, `users/${user.uid}`);
        try {
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            commit('UPDATE_USER_DATA', snapshot.val());
          } else {
            console.log("No additional user data found.");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        // User is signed out, clear additional user data
        commit('UPDATE_USER_DATA', null);
      }
    });
  }
};

const getters = {
  isLoggedIn: state => state.isLoggedIn,
  currentUser: state => {
    if (state.user) {
      return {
        email: state.user.email,
        emailVerified: state.user.emailVerified,
		name: state.user.name,
        uid: state.user.uid,
        phoneNumber: state.user.phoneNumber,
        points: state.user.points // Assuming you have points stored in the user object
      };
    } else {
      return {};
    }
  },
  userPhoneNumber: state => state.user ? state.user.phoneNumber : null,
  userPoints: state => state.user ? state.user.points : null,
  userName: state => state.user ? state.user.name : null,
  isUserDataLoaded: state => state.isUserDataLoaded,
};


export default {
  state,
  mutations,
  actions,
  getters
};
