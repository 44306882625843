const state = {
	cartItemList: [],
	cartTotal: 0,
}

const mutations = {
	'UPDATE_CART' (state, {item, quantity, isAdd}) {
		const record = state.cartItemList.find(element => element.id == item.id);
		if (record) {
			if (isAdd) {
				record.quantity += quantity;
			} else {
				record.quantity = quantity;
			}
		} else {
			state.cartItemList.push({
				...item,
				quantity
			});
		}
		// Recalculate cartTotal after updating cart
		state.cartTotal = state.cartItemList.reduce((total, currentItem) => total + currentItem.price * currentItem.quantity, 0);
	},
	'SET_CART' (state, productList) {
		if (productList) {
			state.cartItemList = productList;
		}
	},
	'REMOVE_CART_ITEM' (state, {item}) {
        const recordIndex = state.cartItemList.findIndex(element => element.id == item.id);
        if (recordIndex !== -1) {
            // Remove the item from cartItemList
            state.cartItemList.splice(recordIndex, 1);

            // Recalculate cartTotal after removing the item
            state.cartTotal = state.cartItemList.reduce((total, currentItem) => total + currentItem.price * currentItem.quantity, 0);
        }
    },
	// Add to mutations in cart.js
	'APPLY_DISCOUNT' (state, pointsRedeemed) {
		const pointsToDollars = {
			300: 10, // Redeem 300 points for $10 off
			500: 30  // Redeem 500 points for $30 off
		};
		const discount = pointsToDollars[pointsRedeemed] || 0;
		let total = 0;
		state.cartItemList.forEach(item => {
			total += item.price * item.quantity;
		});
		// Calculate the new total after applying the discount
		state.cartTotal = Math.max(0, total - discount); // Directly update cartTotal in the state
	}
	

}

const actions = {
	clearCart: ({commit}) => {
		commit('SET_CART', []);
	},
}

const getters = {
	cartItemList: (state) => {
		return state.cartItemList;
	},
	cartValue: (state) => {
        if (state.cartTotal !== undefined) {
            return state.cartTotal;
        } else {
            let res = 0;
            state.cartItemList.forEach(item => {
                res += item.price * item.quantity;
            });
            return res;
        }
    }
}

export default {
	state,
	mutations,
	actions,
	getters
}
