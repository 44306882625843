<template>
<div class="d-flex flex-column sticky-footer-wrapper" :style="{
        // 'background-image': `url(${require('@/assets/brown1.jpg')})`,
        width: '100%',
        height: '100vh', backgroundImage: 'url(' + require('@/assets/donuts.jpg') + ')' 
      }">
    <main class="flex-fill">
        <app-header></app-header>
        <message-component></message-component>
        <div style="padding-top: 0; overflow-x: hidden;">
            <div>
                <div>
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </main>

    <footer class="navbar-default navbar-bottom navbar-dark bg-dark">
        <div class="container-fluid">
            <p class="text-center nav-bar mb-0">© 2024<a href="https://bakerbois.com/" target="_blank">
                    bakerbois.com
                </a></p>
        </div>
    </footer>

</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import Header from './components/Header.vue';
import MessageComponent from './components/common/MessageComponent.vue';

export default {
    data() {
        return {
            loaderColor: "#e3e3e3",
            loaderSize: "50px",
            displayList: false,
        }
    },
    components: {
        appHeader: Header,
        MessageComponent
    },
    methods: {
        ...mapActions(['getShoppingCart', 'listenToProductList'])
    },
    created() {
        let uid = this.$store.getters.currentUser.uid;
        this.listenToProductList();
        this.getShoppingCart({
            uid,
            currentCart: this.$store.getters.cartItemList
        });
    }
}
</script>

<style>
#reset-store-panel {
    position: fixed;
    bottom: 0px;
    right: 0px;
}

.sticky-footer-wrapper {
    min-height: 100vh;
}

.flex-fill {
    flex: 1 1 auto;
}

footer {
    color: #666;
    padding: 10px 0 10px 0;
    font-size: 85%;
}

footer a {
    color: #999;
}

footer a:hover {
    color: #efefef;
}

.full-screen {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    background-repeat: round;
}

h3 {
    font-size: 1.6em;
}

.col-6 {
    font-weight: 400;
    max-width: 40%;
}

.nav-link {
  margin-top: -10px;
}

.navbar-dark .navbar-nav .nav-link {
    color: #331f1f;
    font-family: 'Inter';
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(30, 30, 30, 0.867);
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
    color: #000000;
}

.nav-item {
  background-color: #ffd871;
  border-radius: 8px;
  border: 2px outset black;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  margin-right: 5px;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-align: center;
  justify-content: center;
}

.nav-item:hover,
.nav-item:focus {
  background-color: #ccac53;
  border: 2px inset black;
}

.mt-3, .my-3 {
    margin-top: 1rem !important;
    max-width: 100%;
}


@media (max-width: 576px) {
  
}
</style>
