<template>
<div class="container">
    <div>
        <table id="cart" class="table table-hover table-sm" style="background-color: white; opacity: 0.97;">
            <thead>
                <tr>
                    <th class="products">Product</th>
                    <th class="price" v-if="!isMobile">Price</th>
                    <th class="price" v-else></th>
                    <th class="quantity" v-if="!isMobile">Quantity</th>
                    <th class="quantity" v-else></th>
                    <th class="subtotal" v-if="!isMobile">Subtotal</th>
                    <th class="subtotal"></th>
                </tr>
            </thead>

            <!-- Conditional rendering based on cartItemList length -->
            <tr v-if="cartItemList.length === 0">
                <td colspan="6" class="text-center">Your cart is empty.</td>
            </tr>
            <transition-group name="list-shopping-cart" tag="tbody">
                <app-cart-item v-for="cartItem in cartItemList" :cartItem="cartItem" :key="cartItem.id"></app-cart-item>
            </transition-group>

            <tfoot>
                <tr class="d-table-row d-sm-none">
                    <td class="text-center">
                        <strong v-if="cartItemList.length === 0"></strong>
                        <strong v-else>Total ${{ cartValue }}</strong>
                    </td>
                </tr>
                <tr v-if="this.cartItemList.length !== 0">
                    <td colspan="5" class="text-right">
                        <div class="redeem-section" v-if="this.cartValue > 0">
                            <label style="margin-right: 5px;" for="redeemPoints">Redeem Points:</label>
                            <div class="input-group redeem-input">
                                <input style="font-weight: bold;" type="number" id="redeemPoints" v-model="pointsToRedeem" :max="currentUser.points" min="0" class="form-control">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary redeem-button" @click="redeemPoints">
                                        Redeem
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button class="btn btn-dark save-control" @click="saveShoppingCartLocal">
                            <i class="fa fa-angle-left"></i> {{ isMobile ? 'Save' : 'Save and Continue Shopping' }}
                        </button>
                    </td>
                    <td colspan="2" class="d-none d-sm-table-cell"></td>
                    <td class="d-none d-sm-table-cell text-center">
                        <strong v-if="cartItemList.length === 0">Total $0</strong>
                        <strong v-else>Total ${{ cartValue }}</strong>
                    </td>
                    <td class="px-0">
                        <button class="btn btn-success" @click="checkout">
                            <span class="text-nowrap">Check Out <i class="fa fa-angle-right d-inline"></i></span>
                        </button>
                        <!-- <p>{{status}}</p> -->
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import CartItem from "./cart/CartItem.vue";
import emailjs from "emailjs-com";
import {
    auth
} from "../config/firebaseConfig";
import {
    ref,
    onValue,
    update,
    get
} from 'firebase/database';
import {
    database
} from '../config/firebaseConfig';

export default {
    data() {
        return {
            status: "",
            pointsToRedeem: 0,
            isMobile: false,
            groupedTransactions: {}, // Initialize as an empty object to group transactions
            users: [],
        };
    },
    computed: {
        ...mapGetters([
            "cartItemList",
            "isLoggedIn",
            "products",
            "currentUser",
            "cartValue",
        ]),
        filteredTransactions() {
            // Get transactions of the current user only
            // const currentUserTransactions = this.groupedTransactions[this.currentUser.uid] || [];
            return this.groupedTransactions[this.currentUser.uid]

            // Filter out blank items for each transaction
            // return currentUserTransactions.map(transaction => {
            //     return {
            //         ...transaction,
            //         items: transaction.items.filter(item => item.title || item.price !== undefined)
            //     };
            // });
        },

    },
    components: {
        appCartItem: CartItem,
    },
    async mounted() {
        this.scrollToTop();
        this.checkIsMobile();
        window.addEventListener('resize', this.checkIsMobile);

        const usersRef = ref(database, 'users');
        onValue(usersRef, (snapshot) => {
            this.users = snapshot.val();
        });

        const transactionsRef = ref(database, 'transactions');
        onValue(transactionsRef, (snapshot) => {
            const groupedTransactions = {};
            snapshot.forEach((userSnapshot) => {
                const userId = userSnapshot.key;
                const userTransactions = userSnapshot.val();

                groupedTransactions[userId] = [];

                for (const transactionId in userTransactions) {
                    if (Object.prototype.hasOwnProperty.call(userTransactions, transactionId)) {
                        const transactionData = userTransactions[transactionId];
                        groupedTransactions[userId].push({
                            transactionId: transactionId,
                            ...transactionData,
                        });
                    }
                }
            });

            this.groupedTransactions = groupedTransactions;
            this.checkAndAddFreeRoll();
        });
    },
    methods: {
        ...mapActions([
            "saveShoppingCart",
            "addMessage",
            "saveToTransaction",
            "clearCart",
            "fetchUserData",
            "applyDiscount",
            "updatePoints",
            "updateCart",
            "removeItemInCart"
        ]),
        checkAndAddFreeRoll() {
            if (!this.products || this.products.length === 0 || this.products.some(item => typeof item === 'undefined')) {
                console.warn('Products not fully loaded or contain empty entries');
                return;
            }

            const freeRollProduct = this.products.filter(product => product && product.id).find(product => product.id === 'free-roll');

            if (!freeRollProduct) {
                console.warn('Free-roll product not found');
                return;
            }

            if (this.filteredTransactions && this.filteredTransactions.length === 1) {
                const isFreeRollInCart = this.cartItemList.some(item => item.id === 'free-roll');

                if (!isFreeRollInCart) {
                    const order = {
                        item: {
                            ...freeRollProduct
                        },
                        quantity: 1,
                        isAdd: true,
                    };
                    this.updateCart(order);
                }
            } else {
                const freeRollIndex = this.cartItemList.findIndex(item => item.id === 'free-roll');
                if (freeRollIndex !== -1) {
                    this.removeItemInCart({
                        item: this.cartItemList[freeRollIndex],
                    });
                }
            }
        },

        checkIsMobile() {
            // Set isMobile to true if the screen width is less than or equal to 768px
            this.isMobile = window.innerWidth <= 768;
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        async redeemPoints() {
            if (!this.pointsToRedeem || this.pointsToRedeem < 300) {
                this.addMessage({
                    messageClass: "warning",
                    message: "Please enter a valid number of points to redeem.",
                });
                return;
            }
            if (this.pointsToRedeem > this.currentUser.points) {
                this.addMessage({
                    messageClass: "warning",
                    message: "You don't have enough points",
                });
                return;
            }
            if (this.cartValue < 80) {
                this.addMessage({
                    messageClass: "warning",
                    message: "$80 minimimum not met",
                });
                return;
            }
            await this.applyDiscount(this.pointsToRedeem);
        },
        async updateNewPoints() {
            // Adjusted to pass an object with pointsToRedeem property
            try {
                await this.updatePoints({
                    pointsToRedeem: this.pointsToRedeem
                });
                // After updating points, proceed with any additional logic, such as checkout
                // For example: this.checkout();
            } catch (error) {
                console.error("Error during points redemption:", error);
            }
        },
        async sendSMS(currentUserData) {
            const recipientPhoneNumber = "7186448659"; // Replace with recipient's phone number

            const accountSid = "ACee11431e0c9047c41a5b808975c3336e";
            const authToken = "dc21791a263686e1984ba85993a3be51";
            const twilioPhoneNumber = "9294935252";

            const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;

            const headers = new Headers();
            headers.set(
                "Authorization",
                "Basic " + btoa(`${accountSid}:${authToken}`)
            );
            headers.set("Content-Type", "application/x-www-form-urlencoded");

            // Constructing the message body with order details and user information
            let messageBody = `${currentUserData.name} has placed an order on BakerBois!\n\nTotal Cost: ${this.cartValue}\n\nName: ${currentUserData.name}\nEmail: ${currentUserData.email}\nPhone Number: ${currentUserData.phoneNumber}\nPoints: ${currentUserData.points}\n\nOrdered items:\n`;
            this.cartItemList.forEach((item, index) => {
                messageBody += `${index + 1}. ${item.title} (${item.quantity})\n`;
            });

            const body = new URLSearchParams({
                From: twilioPhoneNumber,
                To: recipientPhoneNumber,
                Body: messageBody,
            });

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: headers,
                    body: body,
                });

                if (!response.ok) {
                    throw new Error("Failed to send SMS");
                }
            } catch (error) {
                console.error("Error sending SMS:", error);
            }
        },
        async sendSMS2(currentUserData) {
            const recipientPhoneNumber = currentUserData.phoneNumber; // Replace with the second recipient's phone number

            const accountSid = "ACee11431e0c9047c41a5b808975c3336e";
            const authToken = "dc21791a263686e1984ba85993a3be51";
            const twilioPhoneNumber = "9294935252";

            const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;

            const headers = new Headers();
            headers.set(
                "Authorization",
                "Basic " + btoa(`${accountSid}:${authToken}`)
            );
            headers.set("Content-Type", "application/x-www-form-urlencoded");

            // Constructing the message body with order details and user information
            let messageBody = `Thank you for placing an order on BakerBois!\n\nTotal Cost: ${this.cartValue}\n\nName: ${currentUserData.name}\nEmail: ${currentUserData.email}\nPhone Number: ${currentUserData.phoneNumber}\nPoints: ${currentUserData.points}\n\nOrdered items:\n`;

            this.cartItemList.forEach((item, index) => {
                messageBody += `${index + 1}. ${item.title} (${item.quantity})\n`;
            });

            messageBody += `\nSomeone from BakerBois will contact you at the provided number to confirm your order.`;

            const body = new URLSearchParams({
                From: twilioPhoneNumber,
                To: recipientPhoneNumber,
                Body: messageBody,
            });

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: headers,
                    body: body,
                });

                if (!response.ok) {
                    throw new Error("Failed to send SMS");
                }
            } catch (error) {
                console.error("Error sending SMS:", error);
            }
        },
        sendEmail() {
            this.isDisabled = true;
            try {
                const data = {
                    clientName: this.name,
                    email: this.currentUser.email,
                    royaltyPoints: this.client,
                    quantity: this.quantity,
                    total: this.cartValue,
                    title: this.title,
                    cartItemList: this.cartItemList,
                };
                emailjs.send(
                    "service_ih09zzs",
                    "template_7arq507",
                    data,
                    "user_2T5qzKdj1mQbojKvR9p8t"
                );
                setTimeout(
                    () =>
                    this.$router.replace({
                        name: "clientdash",
                    }),
                    4000
                );
            } catch (error) {
                console.log({
                    error,
                });
            }
        },
        checkValidCart(itemList, prodList) {
            let isValid = true;
            let message = "";

            itemList.forEach(item => {
                // Skip null or undefined items
                if (!item) return;

                let found = false;
                for (let prodIdx = 0; prodIdx < prodList.length; prodIdx++) {
                    const product = prodList[prodIdx];
                    // Skip null or undefined products
                    if (!product) continue;

                    if (product.id === item.id) {
                        found = true;
                        if (product.quantity && product.quantity >= item.quantity) {
                            message = `Only ${product.quantity} ${item.title} available in stock`;
                            isValid = false;
                            break;
                        }
                    }
                }
                if (!found) {
                    // Handle case where item id is not found in prodList
                    message = `Product with ID ${item.id} not found`;
                    isValid = false;
                }
            });

            return {
                isValid,
                message,
            };
        },

        saveShoppingCartLocal() {
            if (this.isLoggedIn) {
                let {
                    isValid,
                    message
                } = this.checkValidCart(
                    this.cartItemList,
                    this.products
                );

                if (isValid) {
                    this.saveShoppingCart({
                        cartItemList: this.cartItemList,
                        uid: this.currentUser.uid,
                    }).then(() => {
                        this.addMessage({
                            messageClass: "success",
                            message: "Your shopping cart has been saved",
                        });
                        this.$router.push("/");
                    });
                } else {
                    this.addMessage({
                        messageClass: "danger",
                        message: message,
                    });
                }
            } else {
                this.addMessage({
                    messageClass: "warning",
                    message: "Please login to save your cart",
                });
            }
        },
        async initApp() {
            return new Promise(function (resolve, reject) {
                // Listen for auth state changes.
                auth.onAuthStateChanged(function (user) { // Use the auth object from firebaseConfig.js
                    if (user) {
                        var uid = user.uid;

                        const productsRef = ref(database, "users/" + uid);
                        onValue(productsRef, (snapshot) => {
                            var points = snapshot.child("points").val();
                            resolve(points);
                        });

                    } else {
                        reject("User is not logged in");
                    }
                });
            });
        },
        async fetchData() {
            await this.fetchUserData(); // Dispatch fetchUserData action
        },
        async checkout() {
            if (this.isLoggedIn) {
                if (!this.cartItemList || this.cartItemList.length == 0) {
                    this.addMessage({
                        messageClass: "warning",
                        message: "Your cart is empty!",
                    });
                    return;
                }
                if (this.cartValue < 80) {
                    this.addMessage({
                        messageClass: "warning",
                        message: "$80 minimum not met",
                    });
                    return;
                }

                if (!confirm("Are you sure you want to proceed to checkout?")) {
                    return; // If the user clicks 'No', exit the function
                }

                let {
                    isValid,
                    message
                } = this.checkValidCart(
                    this.cartItemList,
                    this.products
                );

                if (isValid) {
                    try {
                        // Fetch current user data
                        const currentUserData = await this.fetchUserData();

                        // Retrieve user points from the database
                        const userRef = ref(database, `users/${currentUserData.uid}`);
                        const snapshot = await get(userRef);
                        const userData = snapshot.val();

                        // Parse existing points to ensure it's a number
                        const existingPoints = parseInt(userData.points) || 0;

                        // Calculate updated points by adding existing points and new points
                        const updatedPoints = existingPoints + this.cartValue;

                        // Update user points in the database
                        await update(userRef, {
                            email: currentUserData.email,
                            points: updatedPoints,
                        });

                        console.log(currentUserData, 'currentUserData')
                        console.log(currentUserData.points, 'currentUserDataPOINTS')

                        if (!currentUserData || currentUserData.points < 0) {
                            this.addMessage({
                                messageClass: "danger",
                                message: "Failed to fetch user data or missing points information. Please try again later.",
                            });
                            return;
                        }

                        // Send SMS
                        this.sendSMS(currentUserData);
                        this.sendSMS2(currentUserData);

                        this.updateNewPoints()

                        // Save transaction to database
                        await this.saveToTransaction({
                            cartItemList: this.cartItemList,
                            uid: currentUserData.uid,
                            date: new Date().toISOString(), // Current date/time
                            status: "ACTIVE", // Transaction status
                        });
                        // Display success message
                        this.addMessage({
                            messageClass: "success",
                            message: "Your order has been successfully processed! Someone will reach out to you within the next 30 minutes.",
                        });

                        // Save shopping cart
                        await this.saveShoppingCart({
                            cartItemList: [],
                            uid: currentUserData.uid,
                        });

                        // Clear cart and navigate to home page
                        this.clearCart();
                        this.fetchData();
                        this.$router.push("/");

                    } catch (error) {
                        console.error("Error processing checkout:", error);
                        this.addMessage({
                            messageClass: "danger",
                            message: "Failed to process the order. Please try again later.",
                        });
                    }
                } else {
                    this.addMessage({
                        messageClass: "danger",
                        message: message,
                    });
                }
            } else {
                this.addMessage({
                    messageClass: "warning",
                    message: "Please login to checkout",
                });
            }
        }

    },
    beforeDestroy() {
        // Remove the event listener when the component is destroyed to prevent memory leaks
        window.removeEventListener('resize', this.checkIsMobile);
    }
};
</script>

<style lang="scss" scoped>
::v-deep label {
    display: inline-block;
    margin-bottom: 0.1rem;
}

.products {
    width: 60%;
    vertical-align: middle !important;
}

.price {
    width: 3%;
    vertical-align: middle !important;
}

.quantity {
    width: 10%;
    vertical-align: middle !important;
    text-align: center;
}

.subtotal {
    width: 5%;
    vertical-align: middle !important;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: middle !important;
    border-top: 1px solid #dee2e6;
}

.redeem-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
}

.redeem-input {
    max-width: 163px;
}

.redeem-button {
    border-radius: 20px;
    background-color: #f8f9fa;
    color: #1b1b1b;
    border-color: #1b1b1b;
    transition: all 0.3s ease;
}

.redeem-button:hover {
    background-color: #1b1b1bb5;
    color: #ffffff;
    border-color: #1b1b1b;
}

.redeem-button:focus {
    box-shadow: none;
    outline: none;
}

.list-shopping-cart-leave-active {
    transition: all 0.4s;
}

.list-shopping-cart-leave-to {
    opacity: 0;
    transform: translateX(50px);
}

::v-deep .table-sm td {
    padding: 1.3rem !important;
}

.table-sm {
    font-size: 0.875rem;

    ::v-deep h4 {
        font-size: 1.25rem;
        padding-left: 5px;
    }
}

::v-deep .form-control {
    width: 65px;
}

@media (max-width: 768px) {

    .products {
        width: 70%;
    }

    .price {
        width: 27% !important;
    }

    .quantity {
        width: 20%;
    }

    .subtotal {
        width: 30% !important;
    }

    .table {
        table-layout: fixed;
    }

    .save-control {
        width: 85px;
    }

    .redeem-section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: space-evenly;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    /* Adjust table column widths */
    table#cart th:nth-child(2),
    table#cart td:nth-child(2) {
        width: 20%;
    }

    table#cart th:nth-child(4),
    table#cart td:nth-child(4) {
        width: 22%;
    }
}
</style>
