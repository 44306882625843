<template>
<div class="admin-panel">
    <div style="display: flex; flex-direction: column; align-items: center;">
        <header class="admin-header">
            <h1 class="admin-title">Admin Panel</h1>
        </header>

        <button @click="showAdminSection = !showAdminSection" class="button toggle-button" style="padding: 10px;">
            {{ showAdminSection ? 'Hide Products' : 'Show Products' }}
        </button>
    </div>

    <!-- Products Section -->
    <section v-if="showAdminSection" class="admin-section">
        <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
            <h2 class="section-title">Products</h2>
            <button @click="showAddProductForm = true" class="button primary" style="margin-bottom: 15px; float: right; width: 55px;">
                <i class="fas fa-plus"></i>
            </button>
        </div>

        <!-- Products Table -->
        <div class="table-container" style="overflow: hidden !important; border-radius: 20px;">
            <table class="table beautiful-table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th v-if="!isMobile">Size</th>
                        <th>Price</th>
                        <th v-if="!isMobile">Quantity</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(product, index) in products" :key="index">
                        <td>{{ product.title }}</td>
                        <td>{{ product.description }}</td>
                        <td v-if="!isMobile">{{ product.size }}</td>
                        <td>${{ product.price }}</td>
                        <td v-if="!isMobile">{{ product.quantity }}</td>
                        <td>
                            <button @click="() => editProduct(product)" class="button secondary buttonMargin" style="margin-right: 5px; width: 55px;" title="Edit Product">
                                <i class="fa fa-edit" aria-hidden="true"></i>
                            </button>
                            <button @click="() => deleteProduct(product)" class="button danger buttonMargin" style="width: 55px;" title="Delete Product">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>

    <!-- Add Product Overlay -->
    <div v-if="showAddProductForm" class="overlay">
        <div class="overlay-content">
            <h3>Add Product</h3>
            <form @submit.prevent="addNewProduct" class="form">
                <div class="form-group">
                    <label for="newTitle">Title:</label>
                    <input type="text" id="newTitle" v-model="newProduct.title" required>
                </div>
                <div class="form-group">
                    <label for="newDescription">Description:</label>
                    <input type="text" id="newDescription" v-model="newProduct.description" required>
                </div>
                <div class="form-group">
                    <label for="newSize">Size:</label>
                    <input id="newSize" v-model="newProduct.size" required>
                </div>
                <div class="form-group">
                    <label for="newQuantity">Quantity:</label>
                    <input type="number" id="newQuantity" v-model="newProduct.quantity">
                </div>
                <div class="form-group">
                    <label for="newPrice">Price:</label>
                    <input type="number" id="newPrice" v-model="newProduct.price" required>
                </div>
                <div class="form-group">
                    <label for="newImageFile">Product Image:</label>
                    <input type="file" id="newImageFile" @change="handleNewImageUpload" accept="image/*">
                </div>
                <div class="button-container">
                    <button type="submit" class="button primary" style="margin-right: 15px;">Submit</button>
                    <button @click="showAddProductForm = false" type="button" class="button secondary">Cancel</button>
                </div>
            </form>
        </div>
    </div>

    <!-- Edit Product Overlay -->
    <div v-if="editingProduct" class="overlay">
        <div class="overlay-content">
            <h3>Edit Product</h3>
            <form @submit.prevent="saveEditedProduct" class="form">
                <div class="form-group">
                    <label for="editTitle">Title:</label>
                    <input type="text" id="editTitle" v-model="editedProduct.title" required>
                </div>
                <div class="form-group">
                    <label for="editDescription">Description:</label>
                    <input type="text" id="editDescription" v-model="editedProduct.description" required>
                </div>
                <div class="form-group">
                    <label for="editSize">Size:</label>
                    <input type="text" id="editSize" v-model="editedProduct.size" required>
                </div>
                <div class="form-group">
                    <label for="editQuantity">Quantity:</label>
                    <input type="number" id="editQuantity" v-model="editedProduct.quantity">
                </div>
                <div class="form-group">
                    <label for="editPrice">Price:</label>
                    <input type="number" id="editPrice" v-model="editedProduct.price" required>
                </div>
                <div class="form-group">
                    <label for="editImageFile">Product Image:</label>
                    <input type="file" id="editImageFile" @change="handleEditImageUpload($event, editedProduct.itemnum)" accept="image/*">
                </div>
                <div class="button-container">
                    <button type="submit" class="button primary" style="margin-right: 15px;">Save</button>
                    <button @click="cancelEdit" type="button" class="button secondary">Cancel</button>
                </div>
            </form>
        </div>
    </div>

    <!-- Transactions Section -->
    <section class="admin-section">
        <h2 class="section-title">Transactions</h2>

        <div class="filter-controls">
            <select v-model="timeFrame" @change="updatePeriodOptions">
                <option value="month">Monthly</option>
                <option value="year">Yearly</option>
            </select>
            <select v-model="selectedPeriod">
                <option v-for="period in periodOptions" :key="period" :value="period">{{ period }}</option>
            </select>
            <select v-model="categorizeByItem">
                <option value="all">All Items</option>
                <option v-for="item in uniqueItems" :key="item" :value="item">{{ item }}</option>
            </select>
        </div>

        <div class="sales-totals">
            <!-- <h3 v-if="sortedSalesTotalsKeys.length > 0" style="display: block;">Sales Totals</h3> -->
            <ul v-if="sortedSalesTotalsKeys.length > 0">
                <li v-for="key in sortedSalesTotalsKeys" :key="key">
                    <span>{{ key }}</span> ${{ salesTotals[key].totalSales.toFixed(2) }} <em>({{ salesTotals[key].totalQuantity }} units sold)</em>
                </li>
            </ul>
            <div v-else>No results found for the selected options.</div>
        </div>

        <div class="transactions-wrapper">
            <div class="active-transactions-notification" v-if="activeTransactionsCount > 0">
                Pending Orders: <span class="pending-count">{{ activeTransactionsCount }}</span>
            </div>
            <!-- <input type="text" v-model="searchQuery" placeholder="Search transactions..." class="search-box"> -->
            <div v-for="date in sortedTransactionDates" :key="date" class="date-transactions-container">
                <h3 class="transaction-date" style="display: block; padding-left: 8px; padding-top: 8px; border-top: 2px groove white;" :style="{ left: `${leftOffset}px` }">{{ date }}</h3>
                <div class="table-container" style="border-radius: 20px;">
                    <table class="table">
                        <tbody>
                            <tr class="tr-control" v-for="(transaction, index) in groupedTransactions[date]" :key="index">
                                <td class="transaction-number" :class="{'transaction-active': transaction.status === 'ACTIVE', 'transaction-non-active': transaction.status !== 'ACTIVE'}">
                                    {{ getUserName(transaction.userId) }} <br> Transaction {{ index + 1 }} <br>
                                    <strong>Total: ${{ calculateTransactionTotal(transaction.items) }}</strong>
                                    <div class="transaction-actions">
                                        <button @click="updateTransactionStatus(transaction.userId, transaction.transactionId, 'ACTIVE')" class="button active-button-control" style="margin-right: 5px; margin-top: 5px;" title="Mark as Active">
                                            <i class="fa fa-clock" aria-hidden="true"></i>
                                        </button>
                                        <button @click="updateTransactionStatus(transaction.userId, transaction.transactionId, 'COMPLETE')" class="button inactive-button-control" title="Mark as Complete">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </button>

                                    </div>
                                </td>
                                <td style="height: 0;">
                                    <div class="flex-container" style="display: flex; overflow-x: auto;">
                                        <div v-for="(item, itemIndex) in transaction.items" :key="itemIndex" class="transaction-item" style="margin-right: 16px;">
                                            <div><strong>{{ item.title }}</strong></div>
                                            <div>Price: {{ item.price }}</div>
                                            <div>Quantity: {{ item.quantity }}</div>
                                            <div>Size: {{ item.size }}</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>

</div>
</template>

<script>
import {
    database
} from '../config/firebaseConfig';
import {
    getStorage,
    ref as storageRef,
    uploadBytes,
    getDownloadURL
} from 'firebase/storage';
import {
    ref,
    onValue,
    set,
    remove,
    update
} from 'firebase/database';
import {
    mapActions,
    mapGetters
} from 'vuex';

export default {
    name: 'Admin',
    data() {
        return {
            showAddProductForm: false,
            showAdminSection: false,
            isLoading: true,
            selectedPeriod: '',
            periodOptions: [],
            timeFrame: 'year',
            categorizeByItem: 'all',
            uniqueItems: [], // To be populated with unique item titles from transactions
            calculatedTotals: null,
            leftOffset: 0,
            searchQuery: '',
            isMobile: false,
            newProduct: {
                itemnum: '',
                id: '',
                title: '',
                price: 0,
                size: '',
                thumbnail_url: '',
                description: '',
                discount: '',
                quantity: 0,
            },
            maxTransactions: 5,
            products: [], // Initialize with an empty array
            groupedTransactions: {}, // Initialize as an empty object to group transactions
            users: [], // Initialize with an empty array
            editingProduct: null,
            editedProduct: {
                title: '',
                price: 0,
                quantity: 0,
                description: '',
                size: '',
                thumbnail_url: ''
            },
        };
    },
    watch: {
        timeFrame(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.updatePeriodOptions();
            }
        },
        selectedPeriod(newVal, oldVal) {
            // This watcher ensures components react to new period selection
            if (newVal !== oldVal) {
                // Call any necessary methods if you need to react to period changes
            }
        }
    },
    mounted() {
        this.checkIsMobile();

        this.initializeData();
    },

    computed: {
        ...mapGetters(['isLoggedIn', 'cartValue', 'currentUser', 'cartItemList', 'userPhoneNumber', 'isUserDataLoaded']),
        visibleTransactions() {
            return userId => this.groupedTransactions[userId] ? this.groupedTransactions[userId].slice(0, this.maxTransactions) : [];
        },
        activeTransactionsCount() {
            return Object.values(this.groupedTransactions).flat().reduce((total, transaction) => {
                return total + (transaction.status === 'ACTIVE' ? 1 : 0);
            }, 0);
        },
        sortedSalesTotalsKeys() {
            const baseFilter = this.timeFrame === 'year' ?
                key => key.startsWith(this.selectedPeriod) :
                key => key.split(' - ')[0] === this.selectedPeriod;

            // Filter keys based on selectedPeriod before sorting
            const keysFilteredBySelectedPeriod = Object.keys(this.salesTotals).filter(baseFilter);

            const keysWithParsedDates = keysFilteredBySelectedPeriod.map(key => {
                const [date, itemName] = key.split(' - ');
                const [year, month] = date.split('-');
                const sortableDate = month ? `${year}-${month.padStart(2, '0')}` : `${year}`;
                return {
                    originalKey: key,
                    sortableDate,
                    itemName // Keep the item name to distinguish individual items
                };
            });

            keysWithParsedDates.sort((a, b) => {
                // Sort by date first, then by item name if the dates are the same
                const dateComparison = new Date(b.sortableDate) - new Date(a.sortableDate);
                return dateComparison === 0 ? a.itemName.localeCompare(b.itemName) : dateComparison;
            });

            return keysWithParsedDates.map(entry => entry.originalKey);
        },
        sortedTransactionDates() {
            return Object.keys(this.groupedTransactions)
                .map(dateStr => {
                    // Convert the date string from "MM/DD/YYYY" to "YYYY-MM-DD"
                    const parts = dateStr.split('/');
                    const sortableDate = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`;
                    return {
                        original: dateStr,
                        sortable: sortableDate
                    };
                })
                .sort((a, b) => new Date(b.sortable) - new Date(a.sortable))
                .map(obj => obj.original);
        },
        itemTotals() {
            const totals = {};
            Object.values(this.groupedTransactions).forEach(transactionsByDate => {
                transactionsByDate.forEach(transaction => {
                    transaction.items.forEach(item => {
                        if (!totals[item.title]) {
                            totals[item.title] = 0;
                        }
                        totals[item.title] += item.quantity;
                    });
                });
            });
            return totals;
        },
        salesTotals() {
            let totals = {};
            // Iterate over each transaction group (by date)
            Object.values(this.groupedTransactions).forEach(transactions => {
                transactions.forEach(transaction => {
                    const transactionDate = new Date(transaction.date);
                    const year = transactionDate.getFullYear().toString();
                    const month = (transactionDate.getMonth() + 1).toString().padStart(2, '0');
                    // Key construction changes based on 'month' or 'year' selection
                    const baseKey = this.timeFrame === 'month' ? `${year}-${month}` : year;

                    transaction.items.forEach(item => {
                        // Skip items not matching the selected category unless 'all' is selected
                        if (this.categorizeByItem !== 'all' && item.title !== this.categorizeByItem) {
                            return;
                        }

                        const itemKey = this.categorizeByItem === 'all' ? baseKey : `${baseKey} - ${item.title}`;
                        if (!totals[itemKey]) {
                            totals[itemKey] = {
                                totalSales: 0,
                                totalQuantity: 0
                            };
                        }

                        // Aggregate sales and quantity
                        totals[itemKey].totalSales += item.price * item.quantity;
                        totals[itemKey].totalQuantity += item.quantity;
                    });
                });
            });

            return totals;
        },

        filteredTransactions() {
            let filtered = {};
            Object.keys(this.groupedTransactions).forEach(userId => {
                // Assuming each transaction now includes { date, items, status }
                filtered[userId] = this.groupedTransactions[userId].map(transaction => {
                    return {
                        ...transaction,
                        items: transaction.items.filter(item => item.title || item.price !== undefined)
                    };
                });
            });
            return filtered;
        }
    },
    methods: {
        ...mapActions(['logout', 'fetchUserData']),
        checkIsMobile() {
            // Set isMobile to true if the screen width is less than or equal to 768px
            this.isMobile = window.innerWidth <= 768;
        },
        async initializeData() {
      this.isLoading = true; // Begin loading state
      await this.fetchData(); // Fetch all necessary data
      this.isLoading = false; // End loading state once all data has been fetched
    },
    async fetchData() {
      this.checkIsMobile();
      await Promise.all([this.fetchUsers(), this.fetchProducts(), this.fetchTransactions()]);
      // After all data is fetched, you can call methods that depend on this data
      this.populateUniqueItems();
      this.updatePeriodOptions();
    },
    fetchUsers() {
      return new Promise((resolve) => {
        const usersRef = ref(database, "users");
        onValue(usersRef, (snapshot) => {
          this.users = snapshot.val() || [];
          resolve();
        });
      });
    },
    fetchProducts() {
      return new Promise((resolve) => {
        const productsRef = ref(database, "1adTQ512s0HkBRmzumb5GKqfkXB0mClz1ekMkmTmQ0EY/products");
        onValue(productsRef, (snapshot) => {
          this.products = snapshot.val() ? Object.values(snapshot.val()).filter(product => product) : [];
          resolve();
        });
      });
    },
    fetchTransactions() {
      return new Promise((resolve) => {
        const transactionsRef = ref(database, "transactions");
        onValue(transactionsRef, (snapshot) => {
          const transactionsByDate = {};
          snapshot.forEach((userSnapshot) => {
            const userId = userSnapshot.key;
            userSnapshot.forEach((transactionSnapshot) => {
              const transactionId = transactionSnapshot.key;
              const transaction = transactionSnapshot.val();
              const date = this.formatDate(transaction.date);
              if (!transactionsByDate[date]) {
                transactionsByDate[date] = [];
              }
              transactionsByDate[date].push({
                ...transaction,
                userId,
                transactionId,
              });
            });
          });
          this.groupedTransactions = transactionsByDate;
          resolve();
        });
      });
    },
        async handleNewImageUpload(event) {
            const file = event.target.files[0];
            if (!file) {
                alert('No file selected!');
                return;
            }
            try {
                const storage = getStorage(); // Initialize Firebase Storage
                const storageReference = storageRef(storage, `product-images/${file.name}`);
                const uploadResult = await uploadBytes(storageReference, file);
                const imageUrl = await getDownloadURL(uploadResult.ref);
                this.newProduct.thumbnail_url = imageUrl; // Save the URL to the newProduct object
                console.log(imageUrl, 'imageUrl')
            } catch (error) {
                console.error("Error uploading file:", error);
                alert('Error uploading file. Please try again.');
            }
        },
        formatSelectedPeriod(period) {
            // Directly return the period as it's already in the expected format
            // This method now acts as a pass-through, but it's kept for future flexibility
            return period;
        },
        updatePeriodOptions() {
            const periods = new Set();
            Object.values(this.groupedTransactions).forEach(transactions => {
                transactions.forEach(transaction => {
                    const date = new Date(transaction.date);
                    const year = date.getFullYear();
                    if (this.timeFrame === 'month') {
                        const month = (date.getMonth() + 1).toString().padStart(2, '0');
                        periods.add(`${year}-${month}`);
                    } else {
                        periods.add(year.toString());
                    }
                });
            });

            this.periodOptions = Array.from(periods).sort((a, b) => b.localeCompare(a));
            this.selectedPeriod = this.periodOptions[0] || '';
        },

        populateUniqueItems() {
            const itemTitles = new Set();
            Object.values(this.groupedTransactions).forEach(transactions => {
                transactions.forEach(transaction => {
                    transaction.items.forEach(item => {
                        itemTitles.add(item.title);
                    });
                });
            });
            this.uniqueItems = Array.from(itemTitles);
        },

        calculateTotals() {
            const totals = {};
            const filterYear = new Date().getFullYear(); // Example for current year, adjust as needed
            Object.values(this.groupedTransactions).flat().forEach(transaction => {
                const transactionDate = new Date(transaction.date);
                const year = transactionDate.getFullYear();
                const month = transactionDate.getMonth() + 1; // 1-12 for months
                const key = this.timeFrame === 'month' ? `${year}-${month.toString().padStart(2, '0')}` : `${year}`;

                if (this.timeFrame === 'year' && year !== filterYear) return; // Example filter, adjust as needed

                transaction.items.forEach(item => {
                    if (this.categorizeByItem !== 'all' && item.title !== this.categorizeByItem) return;

                    if (!totals[key]) totals[key] = 0;
                    totals[key] += item.price * item.quantity;
                });
            });

            this.calculatedTotals = totals;
        },
        calculateTransactionTotal(items) {
            return items.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);
        },
        updateTransactionStatus(userId, transactionId, status) {
            const transactionRef = ref(database, `transactions/${userId}/${transactionId}`);
            update(transactionRef, {
                    status: status
                })
                .catch(error => console.error("Error updating transaction status:", error));
        },

        handleScroll(event) {
            this.leftOffset = event.target.scrollLeft;
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
            return formattedDate;
        },
        getUserName(userId) {
            return this.users && this.users[userId] ? this.users[userId].name : 'Unknown';
        },
        deleteProduct(product) {
            if (confirm("Are you sure you want to delete this product?")) {
                const productRef = ref(database, `1adTQ512s0HkBRmzumb5GKqfkXB0mClz1ekMkmTmQ0EY/products/${product.itemnum}`);
                remove(productRef).then(() => {
                    console.log("Product deleted successfully!");
                    // Optionally refresh the products list here
                });
            }
        },
        toggleAddProductForm() {
            this.showAddProductForm = !this.showAddProductForm;
        },
        async addNewProduct() {
            if (!this.newProduct.thumbnail_url) {
                alert('Please wait for the image to finish uploading or ensure an image is selected.');
                return;
            }

            const nextProductNumber = this.products.length + 1;
            const newProductKey = nextProductNumber.toString();
            this.newProduct.itemnum = newProductKey;

            const newProductRef = ref(database, `1adTQ512s0HkBRmzumb5GKqfkXB0mClz1ekMkmTmQ0EY/products/${this.newProduct.itemnum}`);
            set(newProductRef, this.newProduct).then(() => {
                this.resetNewProductForm();
            });
        },
        showMoreButton(userId) {
            return this.groupedTransactions[userId].length > this.maxTransactions;
        },
        showLessButton(userId) {
            return this.maxTransactions !== this.groupedTransactions[userId].length && this.maxTransactions > 5;
        },
        showAllTransactions(userId) {
            this.maxTransactions = this.groupedTransactions[userId].length;
        },
        showLimitedTransactions() {
            this.maxTransactions = 5;
        },
        editProduct(product) {
            this.editingProduct = product;
            this.editedProduct.itemnum = product.itemnum;
            this.editedProduct.title = product.title;
            this.editedProduct.price = product.price;
            this.editedProduct.id = product.id;
            this.editedProduct.quantity = product.quantity;
            this.editedProduct.description = product.description;
            this.editedProduct.size = product.size;
            this.editedProduct.thumbnail_url = product.thumbnail_url;

        },
        saveEditedProduct() {
            const editedProductRef = ref(database, `1adTQ512s0HkBRmzumb5GKqfkXB0mClz1ekMkmTmQ0EY/products/${this.editingProduct.itemnum}`);
            update(editedProductRef, this.editedProduct).then(() => {
                this.editingProduct = null;
                // Optionally refresh the products list here
            });
        },
        resetNewProductForm() {
            this.newProduct = {
                itemnum: '',
                id: '',
                title: '',
                price: 0,
                size: '',
                thumbnail_url: '',
                description: '',
                discount: '',
                quantity: 0,
            };
            this.showAddProductForm = false;
        },
        cancelEdit() {
            this.editingProduct = null;
        },
    },

};
</script>

<style scoped>
/* Base Styles */
body,
html {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    color: #333;
    margin: 0;
    padding: 0;
    background-color: #f7f8fa;
}

.active-transactions-notification {
    margin: 20px 0;
    border-radius: 20px;
    color: #333;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
}

.pending-count {
    font-size: 24px;
    /* Make the count larger */
    font-weight: 600;
    /* Make the count bolder */
    margin-left: 5px;
}

.item-totals-section,
.filter-controls,
.sales-totals {
    border-radius: 14px;
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); */
}

.item-totals-section h3,
.sales-totals h3 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    padding: 10px 0;
    border-bottom: 1px solid #ececec;
}

li:last-child {
    border-bottom: none;
}

/* Filter Controls Styling */
.filter-controls {
    background-color: #fff;
    border-radius: 14px;
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); */
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center
}

.filter-controls select {
    padding: 10px 10px;
    margin: 3px 2px;
    border-radius: 10px;
    border: 2px groove #ffffff54;
    font-size: 16px;
    text-align: center;
    color: #333;
    background-color: #fafafa;
}

/* Sales Totals Styling */
.sales-totals ul {
    margin: 0;
    padding: 0;
}

.sales-totals li {
    font-size: 16px;
    color: #333;
    padding: 10px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.sales-totals span {
    font-weight: 500;
}

.active-button-control {
    width: unset;
}

.inactive-button-control {
    width: unset;
}

.transaction-active {
    background-color: rgb(255, 233, 233) !important;
    /* Or use a specific shade of red as needed */
}

.transaction-active td {
    color: rgb(0, 0, 0);
    /* Or another color that ensures good readability against the red background */
}

.transaction-non-active {
    background-color: #ecffec !important;
    /* Or use a specific shade of red as needed */
}

.transaction-non-active td {
    color: rgb(0, 0, 0);
    /* Or another color that ensures good readability against the red background */
}

.transaction-date {
    position: relative;
    background-color: white;
    z-index: 10;
}

.transactions-wrapper {
    overflow-x: auto;
    position: relative;
    border: 2px groove #ffffff99;
    border-radius: 20px;
    background: white;
}

.search-box {
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

b, strong {
    font-weight: 500;
}

.transaction-number {
    width: 200px;
    height: 140px;
    z-index: 999;
    background: white;
    border-radius: 20px;
    border: 2px groove #ffffff57;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.transaction-item {
    width: 135px;
    background: white;
}

.tr-control {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    background: white;
    border-radius: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.overlay-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    margin-top: 60px;
}

/* Admin Panel Styles */
.admin-panel {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
}

.admin-header {
    text-align: center;
    margin-bottom: 10px;
}

.admin-title {
    font-size: 24px;
    color: #000;
    font-weight: bold;
    margin-top: 30px;
}

/* Section Styles */
.admin-section {
    margin-top: 20px;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
    overflow-x: overlay;
}

.section-title {
    font-size: 20px;
    color: #333;
    position: sticky;
    left: 0;
}

/* Button Styles */
.button {
    background-color: #616162;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #005ecb;
}

.button.secondary {
    background-color: #f4f4f5;
    color: #333;
}

.button.danger {
    background-color: #ff3b30;
}

/* Form Styles */
.form-container {
    background-color: #f4f4f5;
    padding: 20px;
    border-radius: 12px;
}

.form-group {
    margin-bottom: 5px;
}

.form-group label {
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
}

/* Table Styles */
/* .table-container {
    margin-top: 60px;
} */

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 0 !important;
}

.table th,
.table td {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #eee;
    position: sticky;
    left: 0;
    background: white;
}

.table th {
    background-color: #f4f4f5;
    color: #000;
}

.table tbody tr:nth-child(even) {
    background-color: #ffffff;
}

/* Responsive Design */
@media (max-width: 768px) {

    .buttonMargin {
        margin-bottom: 5px;
    }

    .filter-controls {
        flex-direction: row;
        display: flex;
        justify-content: center;
    }

    /* .filter-controls select {
        margin-bottom: 10px;
    } */

    .filter-controls select:last-child {
        margin-bottom: 0;
    }

    .active-button-control {
        width: 40px;
    }

    .inactive-button-control {
        width: 40px;
    }

    .table-container {
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .transaction-item {
        width: 100px;
    }

    .transaction-number {
        width: 140px;
    }

    .admin-panel {
        padding: 10px;
    }

    .form-container,
    .table-container {
        width: 100%;
    }

    .button {
        font-size: 16px;
        padding: 6px;
    }
}
</style>
