<template>
<div class="booking-container">
    <button @click="toggleForm" :class="showForm ? 'hide-button' : 'booking-button'">
        {{ showForm ? 'Hide Booking Form' : 'Book an Event' }}
    </button>
    <transition name="fade">
        <div v-if="showForm" class="booking-form">
            <h2>🎉 Book an Event 🎉</h2>
            <p class="marketing-text">
                Affordable booking with a well-established roller in NYC. Make your event unforgettable with our premium cannagar rolling services!
            </p>
            <form @submit.prevent="submitForm">
                <div class="form-group">
                    <label for="name">Your Name:</label>
                    <input type="text" id="name" v-model="formData.name" required>
                </div>
                <div class="form-group">
                    <label for="email">Your Email:</label>
                    <input type="email" id="email" v-model="formData.email" required>
                </div>
                <div class="form-group">
                    <label for="phone">Your Phone:</label>
                    <input type="tel" id="phone" v-model="formData.phone" @input="formatPhoneNumber" required>
                </div>
                <div class="form-group">
                    <label for="eventDetails">Event Details:</label>
                    <div class="event-details">
                        <input type="date" class="hide_placeholder" placeholder="Date" id="eventDate" v-model="formData.eventDate" required>
                        <select placeholder="Time" id="eventTime" v-model="formData.eventTime" required>
                            <option value="">Time</option>
                            <option placeholder="Time" v-for="time in timeOptions" :key="time" :value="time">{{ time }}</option>
                        </select>
                        <input type="number" id="eventDuration" v-model="formData.eventDuration" placeholder="Hours" required>
                    </div>
                </div>
                <div class="form-group">
                    <label for="eventLocation">Event Location:</label>
                    <input type="text" id="eventLocation" v-model="formData.eventLocation" required>
                </div>
                <div class="form-group">
                    <label for="additionalInfo">Additional Info:</label>
                    <textarea id="additionalInfo" v-model="formData.additionalInfo"></textarea>
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
    </transition>
</div>
</template>

<script>
export default {
    data() {
        return {
            showForm: false,
            formData: {
                name: '',
                email: '',
                phone: '',
                eventDate: '',
                eventTime: '',
                eventDuration: '',
                eventLocation: '',
                additionalInfo: '',
            },
            timeOptions: this.generateTimeOptions()
        };
    },
    methods: {
        toggleForm() {
            this.showForm = !this.showForm;
        },
        async submitForm() {
            // Handle form submission
            console.log('Form submitted', this.formData);
            await this.sendSMS(this.formData);
            alert('Thank you for your booking request. We will get back to you shortly.');

            // Reset the form
            this.formData = {
                name: '',
                email: '',
                phone: '',
                eventDate: '',
                eventTime: '',
                eventDuration: '',
                eventLocation: '',
                additionalInfo: '',
            };
        },
        async sendSMS(formData) {
            const recipientPhoneNumber = "7186448659"; // Replace with recipient's phone number

            const accountSid = "ACee11431e0c9047c41a5b808975c3336e";
            const authToken = "dc21791a263686e1984ba85993a3be51";
            const twilioPhoneNumber = "9294935252";

            const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;

            const headers = new Headers();
            headers.set(
                "Authorization",
                "Basic " + btoa(`${accountSid}:${authToken}`)
            );
            headers.set("Content-Type", "application/x-www-form-urlencoded");

            let messageBody = `New Event Booking Request!\n\nName: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nEvent Date: ${formData.eventDate}\nEvent Time: ${formData.eventTime}\nEvent Duration: ${formData.eventDuration} hours\nEvent Location: ${formData.eventLocation}\nAdditional Info: ${formData.additionalInfo}`;

            const body = new URLSearchParams({
                From: twilioPhoneNumber,
                To: recipientPhoneNumber,
                Body: messageBody,
            });

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: headers,
                    body: body,
                });

                if (!response.ok) {
                    throw new Error("Failed to send SMS");
                }
            } catch (error) {
                console.error("Error sending SMS:", error);
            }
        },
        generateTimeOptions() {
            const times = [];
            for (let hour = 0; hour < 24; hour++) {
                for (let minutes = 0; minutes < 60; minutes += 30) {
                    const time = this.formatTime(hour, minutes);
                    times.push(time);
                }
            }
            return times;
        },
        formatTime(hour, minutes) {
            const period = hour >= 12 ? 'PM' : 'AM';
            const formattedHour = hour % 12 || 12; // Convert 24-hour format to 12-hour format
            const formattedMinutes = String(minutes).padStart(2, '0');
            return `${formattedHour}:${formattedMinutes} ${period}`;
        },
        formatPhoneNumber(event) {
            let input = event.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
            if (input.length <= 3) {
                input = input.replace(/^(\d{0,3})/, '($1');
            } else if (input.length <= 6) {
                input = input.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
            } else {
                input = input.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
            }
            event.target.value = input;
            this.formData.phone = input; // Update the model
        }
    },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

input[type="date"]:before {
    color: rgba(129, 135, 126, 1);
    content: attr(placeholder);
    min-width: fit-content;
    margin-right: 6px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 8px;
    font-size: 15px;
    max-width: 50%;
}

input[type="date"].full:before {
    color: black;
    content: "" !important;
}

input[type="time"]:before {
    color: rgba(53, 53, 53, 0.44);
    content: attr(placeholder);
}

input[type="time"].full:before {
    color: black;
    content: "" !important;
}

select {
    color: black;
}

select:has(option[value=""]:checked) {
    color: gray;
}

.booking-container {
    text-align: center;
    margin-top: 20px;
}

.booking-button,
.hide-button {
    background-color: #352925;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 18px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
}

.hide-button {
    background-color: #ff7f50b3;
}

.booking-button:hover,
.hide-button:hover {
    background-color: #FF4500;
    transform: scale(1.05);
}

.booking-form {
    max-width: 90%;
    margin: 20px auto;
    padding: 30px;
    border: 2px solid #3f332e0d;
    border-radius: 15px;
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
}

.booking-form h2 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 28px;
    color: #FF7F50;
    position: relative;
}

.booking-form h2::after {
    content: '';
    width: 50px;
    height: 5px;
    background-color: #FF7F50;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.marketing-text {
    text-align: center;
    margin-bottom: 20px;
    font-size: 18px;
    color: #dc7854;
    font-weight: 500;
    background-color: #fff6e76e;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #FF6347;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
}

.marketing-text .icon {
    font-size: 24px;
    margin-right: 10px;
}

.booking-form .form-group {
    margin-bottom: 15px;
    text-align: left;
}

.booking-form label {
    /* display: block; */
    margin-bottom: 5px;
    font-weight: 600;
    color: #FF7F50;
}

.booking-form input,
.booking-form textarea,
.booking-form select {
    width: 100%;
    padding: 12px;
    border: 2px solid #FF7F50;
    border-radius: 8px;
    background-color: #fff;
    box-sizing: border-box;
    transition: border-color 0.3s ease, background-color 0.3s ease;
}

.booking-form input:focus,
.booking-form textarea:focus,
.booking-form select:focus {
    border-color: #FF6347;
    outline: none;
    background-color: #FFE4B5;
}

.event-details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.event-details input[type="date"],
.event-details select,
.event-details input[type="number"] {
    flex: 1;
    padding: 12px;
    border: 2px solid #FF7F50;
    border-radius: 8px;
    box-sizing: border-box;
}

.booking-form button {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #FF7F50;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.booking-form button:hover {
    background-color: #FF4500;
    transform: scale(1.05);
}

.booking-form select {
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;utf8,<svg fill="none" stroke="%23FF7F50" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6 9l6 6 6-6"></path></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    padding-right: 30px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

@media (max-width: 767.98px) {

    .event-details {
        flex-direction: column;
    }

    .booking-form {
        max-width: 600px;
    }

    .event-details select,
    .event-details input[type="number"] {
        width: calc(50% - 10px);
    }

    .event-details input[type="date"] {
        width: 200px !important;
        flex: unset;
        width: calc(100% - 5px);
        white-space: nowrap;
    }
}
</style>
